<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
        <a class="navbar-brand" href="/">
            <img v-if="currentDefaultCompany != null" id="logo" :src="currentDefaultCompany.logo.url" alt="logo">
        </a>
        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" v-for="route of allowedRoutes">
                    <router-link :to="route" active-class="active" class="nav-link">
                        {{ __('nav.' + route.name) }}
                    </router-link>
                </li>

                <li class="nav-item dropdown" v-if="globalVars.user != null">
                    <a v-if="currentDefaultCompany != null" class="nav-link" :class="{ 'dropdown-toggle': companiesWithoutCurrent.length > 0 }" href="#" data-toggle="dropdown"
                       role="button" aria-haspopup="true" aria-expanded="false">
                        {{ currentDefaultCompany.name | capitalize }}
                    </a>
                    <div class="dropdown-menu" v-if="companiesWithoutCurrent.length > 0">
                        <a href="#" class="dropdown-item" v-for="company of companiesWithoutCurrent" @click.prevent="setCompany(company.id)">
                            {{ company.name | capitalize }}
                        </a>
                    </div>
                </li>

                <li class="nav-item dropdown" v-if="globalVars.user != null">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
                       role="button" aria-haspopup="true" aria-expanded="false">
                        {{ currentUserName }}
                    </a>
                    <div class="dropdown-menu">
                        <router-link v-if="hasPermission('manage_users')" :to="{ name: 'manage_users' }" active-class="active" class="dropdown-item">
                            <i class="far fa-user"></i> {{ __('nav.manage_users') }}
                        </router-link>
                        <router-link v-if="hasPermission('manage_companies')" :to="{ name: 'manage_companies' }" active-class="active" class="dropdown-item">
                            <i class="fas fa-industry"></i> {{ __('nav.manage_companies') }}
                        </router-link>
                        <router-link v-if="hasPermission('manage_account')" :to="{ name: 'manage_account' }" active-class="active" class="dropdown-item">
                            <i class="fas fa-cog"></i> {{ __('nav.manage_account') }}
                        </router-link>
                        <form method="post" action="/logout">
                            <input type="hidden" name="_token" :value="globalVars.csrf_token">
                            <button type="submit" class="dropdown-item"><i class="fas fa-sign-out-alt"></i> {{ __('nav.logout') }}</button>
                        </form>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        computed: {
            allowedRoutes: function () {
                return this.globalVars.routes.filter((route) => {
                    if (!_.isNil(route.meta)) {
                        if (!!route.meta.is_menu_item) {
                            if (!!route.meta.requireAuth) {
                                return this.hasPermission(route.name);
                            }

                            return true;
                        }
                    }
                });
            },
            currentUserName: function () {
                if (!_.isNil(this.globalVars.user)) {
                    return this.globalVars.user.name;
                }

                return '';
            },
            currentDefaultCompany: function () {
                if (!_.isNil(this.globalVars.user) && !_.isNil(this.globalVars.user.default_company)) {
                    return this.globalVars.user.default_company;
                }

                return null;
            },
            companiesWithoutCurrent: function () {
                if (!_.isNil(this.globalVars.user) && !_.isNil(this.globalVars.user.companies)) {
                    return this.globalVars.user.companies.filter((company) => {
                        return company.id !== this.globalVars.user.default_company_id;
                    });
                }

                return [];
            },
        },
        methods: {
            setCompany(companyID) {
                if (!_.isNil(companyID) && _.isInteger(companyID)) {
                    axios
                        .put('/api/users', {
                            user: {
                                default_company_id: companyID,
                            }
                        })
                        .then(response => {
                            if (!_.isEmpty(response) && !_.isNil(response.data.result)) {
                                window.location.reload();
                            }
                        })
                        .catch(error => {
                            this.globalVars.globalError = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
        },
    }
</script>
