<template>
    <div class="modal fade" id="create-model-modal" tabindex="-1" role="dialog" aria-labelledby="create-model-modal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('model.model.name') | capitalize }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="create-model-form" action="" method="post" @submit.prevent="createNewModel">
                    <div class="modal-body">
                        <error-message :error="error"></error-message>

                        <div class="form-group">
                            <label for="model-number">{{ __('model.model.name') | capitalize }} {{ __('model.model.attr.number') }}</label>
                            <input type="text" v-model="newModel.number" id="model-number" class="form-control" maxlength="3" required
                                   :title="__('title.input.model_field', {
                                       field: __('model.model.attr.number'),
                                       model: __('model.model.name')
                                   }) | capitalize"
                                   :placeholder="__('model.model.attr.number') | capitalize">
                        </div>
                        <div class="form-group">
                            <label for="model-name">{{ __('model.model.name') | capitalize }} {{ __('model.model.attr.name') }}</label>
                            <input type="text" v-model="newModel.name" id="model-name" class="form-control" required
                                   :title="__('title.input.model_field', {
                                       field: __('model.model.attr.name'),
                                       model: __('model.model.name')
                                   }) | capitalize"
                                   :placeholder="__('model.model.attr.name') | capitalize">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('button.close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('button.save', { model: __('model.model.name') }) | capitalize }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from "../ErrorMessage";

    export default {
        components: {
            ErrorMessage,
        },
        data() {
            return {
                newModel: new Model(),
                error: new _Error(),
                StatusMapper: StatusMapper,
            }
        },
        created() {
            let self = this;

            $(function () {
                $('#create-model-modal').on('hidden.bs.modal', function () {
                    self.error = new _Error();
                    self.resetForm('create-model-form');
                });
            });
        },
        methods: {
            createNewModel() {
                this.error = new _Error();

                if (!_.isNil(this.newModel) && !_.isNil(this.newModel.number) && !_.isNil(this.newModel.name)) {
                    axios
                        .put('/api/models', {
                            model: this.newModel,
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_CREATED) {
                                    this.successfullyAdded = true;
                                    this.newModel = new Model();

                                    this.resetForm('create-model-form');

                                    this.emitEvent('get-models');
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            }
        }
    }
</script>
