<template>
    <div class="modal fade" id="create-group-modal" tabindex="-1" role="dialog" aria-labelledby="create-group-modal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('model.group.name') | capitalize }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="create-new-group-form" action="" method="post" @submit.prevent="createNewGroup">
                    <div class="modal-body">
                        <error-message :error="error"></error-message>

                        <div class="form-group" v-if="parentModels != null && parentModels.length > 0">
                            <label for="select-model">{{ __('model.model.name') | capitalize }}</label>
                            <select id="select-model" class="form-control" v-model="selectedModelID" required>
                                <option value="0" disabled>{{ __('text.select_item') }}</option>
                                <option v-for="{ id, number, name } in parentModels" :value="id">
                                    {{ number }}: {{ name }}
                                </option>
                            </select>
                        </div>

                        <template v-if="newGroup != null">
                            <div class="form-group">
                                <label for="model-number">{{ __('model.group.name') | capitalize }} {{ __('model.group.attr.number') }}</label>
                                <input type="number" v-model="newGroup.number" id="model-number" class="form-control" required
                                       :title="__('title.input.model_field', {
                                           field: __('model.group.attr.number'),
                                           model: __('model.group.name')
                                       }) | capitalize"
                                       :placeholder="__('model.group.attr.number') | capitalize">
                            </div>
                            <div class="form-group">
                                <label for="model-name">{{ __('model.group.name') | capitalize }} {{ __('model.group.attr.name') }}</label>
                                <input type="text" v-model="newGroup.name" id="model-name" class="form-control" required
                                       :title="__('title.input.model_field', {
                                           field: __('model.group.attr.name'),
                                           model: __('model.group.name')
                                       }) | capitalize"
                                       :placeholder="__('model.group.attr.name') | capitalize">
                            </div>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('button.close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('button.save', { model: __('model.group.name') }) | capitalize }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from '../../eventbus';
    import ErrorMessage from "../ErrorMessage";

    export default {
        components: {
            ErrorMessage,
        },
        data() {
            return {
                selectedModelID: 0,
                newGroup: new Group(),
                error: new _Response(),
                StatusMapper: StatusMapper,
            }
        },
        props: {
            parentModels: Array,
        },
        created() {
            let self = this;

            EventBus.$on('model-selected', (modelID) => {
                if (!_.isNil(modelID) && _.isInteger(modelID)) {
                    this.selectedModelID = modelID;
                }
            });

            $(function () {
                $('#create-group-modal').on('hidden.bs.modal', () => {
                    self.error = new _Error();
                    self.newGroup = new Group();
                    self.resetForm('create-new-group-form');
                });
            });
        },
        destroyed() {
            EventBus.$off('model-selected');
        },
        methods: {
            createNewGroup() {
                this.error = new _Error();

                if (!_.isNil(this.selectedModelID) && !_.isEmpty(this.newGroup)) {
                    axios
                        .post('/api/models/' + this.selectedModelID + '/groups', {
                            group: this.newGroup,
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    this.newGroup = new Group();
                                    this.resetForm('create-new-group-form');

                                    this.emitEvent('invalidate-groups');
                                    this.emitEvent('get-models', {
                                        selectedModelID: this.selectedModelID,
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
        }
    }
</script>
