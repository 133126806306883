<template></template>

<script>
    export default {
        methods: {
            linkOcrProcessToFigure(figureID, ocrProcess) {
                if (_.isNumber(figureID)) {
                    return axios
                        .post(`/api/figures/${figureID}/ocr-processes`, {
                            ocr_processes: [ocrProcess],
                        });
                }

                return Promise.resolve();
            },
        },
    }
</script>
