<template>
    <div class="modal fade" id="edit-hotspots-modal" tabindex="-1" role="dialog" aria-labelledby="edit-hotspots-modal" aria-hidden="true" data-focus="false">
        <div class="modal-dialog modal-full-width h-100 mt-0 mb-0" role="document">
            <div class="modal-content h-100">
                <div class="modal-header">
                    <h5 class="modal-title">Edit hotspot: {{ ocrProcess.name }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <error-message :error="error" :timeout="true" :toast="true" positioning="floating"
                                   enter-animation="fadeInLeft" leave-animation="fadeOutLeft" classes="mt-3"></error-message>

                    <div v-show="globalVars.isLoading">
                        {{ __('text.loading_models') | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                    <div v-show="!globalVars.isLoading" class="row h-100">
                        <div class="col-sm-4">
                            <div id="validation-data-list" class="scrollable scrollable-md"></div>
                            <div>
                                <button type="button" class="btn btn-outline-danger mt-3 validation-data-list-remove-excess-hotspots"
                                        :title="__('hotspots_editor.remove_excess_hotspots.title')">{{ __('hotspots_editor.remove_excess_hotspots.text') }}</button>
                            </div>
                        </div>
                        <div class="col-sm">
                            <div id="editor-controls" class="position-absolute m-3 p-3 bg-white border border-primary"></div>
                            <object id="svg-object" class="d-block w-100 h-100" :data="svgUrl" type="image/svg+xml"></object>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-light" :title="__('hotspots_editor.previous_hotspot_button.title')"
                            @click="$emit('switch-ocr-process', {ocr_process: ocrProcess, direction: 'previous'})">{{ __('hotspots_editor.previous_hotspot_button.text') }}</button>
                    <button type="button" class="btn btn-primary editor-controls-save">{{ __('button.save', {model: __('model.hotspot.name_plural') }) | capitalize }}</button>
                    <button type="button" class="btn btn-light" :title="__('hotspots_editor.next_hotspot_button.title')"
                            @click="$emit('switch-ocr-process', {ocr_process: ocrProcess, direction: 'next'})">{{ __('hotspots_editor.next_hotspot_button.text') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Editor} from "@osp/explode2view-editor/src/editor";
    import ErrorMessage from "../ErrorMessage";

    export default {
        components: {
            ErrorMessage,
        },
        data() {
            return {
                error: new _Error(),
                editor: new Editor(),
                svgUrl: '',
            }
        },
        props: {
            ocrProcess: {
                type: Object,
                default: function () {
                    return new OcrProcess();
                },
            },
        },
        watch: {
            ocrProcess: function () {
                let editHotspotsModal = $('#edit-hotspots-modal');
                let svgObject = $('#svg-object');

                if (editHotspotsModal.length > 0 && svgObject.length > 0) {
                    if ((editHotspotsModal.data('bs.modal') || {_isShown: false})._isShown) {
                        this.initEditor(svgObject);
                    }
                }
            }
        },
        mounted() {
            let editHotspotsModal = $('#edit-hotspots-modal');
            let svgObject = $('#svg-object');

            if (editHotspotsModal.length > 0 && svgObject.length > 0) {
                editHotspotsModal.on('show.bs.modal', () => {
                    this.initEditor(svgObject);
                });
            }
        },
        methods: {
            fetchFigureProducts(figureID) {
                return axios
                    .get('/api/figures/' + figureID, {
                        params: {
                            with_products: '',
                        }
                    });
            },
            initEditor(svgObject) {
                svgObject.off('load');

                if (!_.isNil(this.ocrProcess)) {
                    this.fetchFigureProducts(this.ocrProcess.figure_id).then(response => {
                        if (!_.isEmpty(response)) {
                            this.error = response.data.error;

                            if (response.status === HTTP_OK) {
                                let validationData = [];

                                for (let product of response.data.result.products) {
                                    validationData.push({
                                        text: product.number,
                                        value: product.pivot.position,
                                        occurrences: product.pivot.amount,
                                    });
                                }

                                this.svgUrl = this.ocrProcess.svg_url;

                                svgObject.on('load', () => {
                                    this.editor.init({
                                        id: 'svg-object',
                                        hotspot_id_prefix: 'SVG_',

                                        controls_container_id: 'editor-controls',
                                        collapse_controls: true,

                                        validation_data_container_id: 'validation-data-list',
                                        validation_data: validationData,

                                        max_scale: 5,
                                        min_hotspot_width: 40,
                                        min_hotspot_height: 30,

                                        on_save: (event) => {
                                            if (!_.isNil(event.detail) && !_.isNil(event.detail.hotspots)) {
                                                OcrApi.saveOcrProcess(this.ocrProcess.id, {
                                                    background_url: this.ocrProcess.background_url,
                                                    hotspots: event.detail.hotspots,
                                                })
                                                    .then(response => {
                                                        if (response.status === HTTP_OK) {
                                                            this.error = response.data.error;

                                                            this.$emit('hotspots-saved', this.ocrProcess.id);
                                                        }
                                                    })
                                                    .catch(error => {
                                                        this.error = this.createErrorFromRequest(error);
                                                    });
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    });
                }
            },
        },
    }
</script>
