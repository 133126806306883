<template>
    <div style="height: 1.8rem;">
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div v-show="!confirm" class="confirm-button">
                <button type="button" class="btn btn-danger btn-sm" @click.stop="confirm = true"><i class="fas fa-trash"></i></button>
            </div>
        </transition>

        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div v-show="confirm" class="confirm-button">
                <button type="button" class="btn btn-success btn-sm ml-1 mr-1" @click="confirm = false; $emit('confirmed', data)"><i class="fas fa-check"></i></button>
                <button type="button" class="btn btn-danger btn-sm ml-1 mr-1" @click.stop="confirm = false; $emit('canceled', data)"><i class="fas fa-times"></i></button>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                confirm: false,
            }
        },
        props: {
            data: null,
        },
    }
</script>
