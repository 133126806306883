<template>
    <transition-group name="list-staggered" :tag="tag" :css="false"
                      @before-enter="beforeEnter" @enter="enter" @leave="leaveAnimation">
        <slot></slot>
    </transition-group>
</template>

<script>
    export default {
        props: {
            tag: {
                type: String,
                default: 'ul',
            },
            staggeringDelay: {
                type: Number,
                default: 100,
            },
            disableLeaveAnimation: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            leaveAnimation() {
                return !this.disableLeaveAnimation ? this.leave : this.noLeave;
            },
        },
        methods: {
            beforeEnter: function (el) {
                el.style.opacity = 0;
                el.style.height = 0;
            },
            enter: function (el, done) {
                let delay = el.dataset.index * this.staggeringDelay;
                setTimeout(function () {
                    $(el).animate(
                        {opacity: 1, height: '100%'},
                        {complete: done},
                    )
                }, delay);
            },
            leave: function (el, done) {
                let delay = el.dataset.index * this.staggeringDelay;
                setTimeout(function () {
                    $(el).animate(
                        {opacity: 0, height: 0},
                        {complete: done},
                    )
                }, delay);
            },
            noLeave() {
                // No leave animation
            },
        },
    }
</script>
