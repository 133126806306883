<template>
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col-md">
                    <div class="row">
                        <div class="col">
                            <h3>{{ __('imports.text.import_figures') }}</h3>

                            <div class="row">
                                <div class="col-md-8">
                                    <form id="import-figures-form" method="post" action="" enctype="multipart/form-data" @submit.prevent="importFiles('import-figures-form')">
                                        <div class="form-group">
                                            <label for="figures">{{ __('imports.label.select_files') }}</label><br>
                                            <input type="file" id="figures" class="form-control" name="figure-files[]" accept="text/plain" multiple required
                                                   @change="filesAreValid = validateFiles($event.target.files)">
                                        </div>

                                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('button.import') }}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div class="row">
                        <div class="col">
                            <h3>{{ __('imports.text.import_product_info_files') }}</h3>

                            <div class="row">
                                <div class="col">
                                    <form id="import-product-info-files" method="post" action="" enctype="multipart/form-data" @submit.prevent="importFiles('import-product-info-files')">
                                        <div class="form-group">
                                            <label for="product-info-files">{{ __('imports.label.select_files') }}</label><br>
                                            <input type="file" id="product-info-files" class="form-control" name="product-info-files[]" multiple required
                                                   @change="filesAreValid = validateFiles($event.target.files)">
                                        </div>

                                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('button.import') }}</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <h3>{{ __('imports.text.import_product_thumbnails') }}</h3>

                            <div class="row">
                                <div class="col">
                                    <form id="import-product-thumbnails" method="post" action="" enctype="multipart/form-data" @submit.prevent="importFiles('import-product-thumbnails')">
                                        <div class="form-group">
                                            <label for="product-thumbnails">{{ __('imports.label.select_files') }}</label><br>
                                            <input type="file" id="product-thumbnails" class="form-control" name="product-thumbnails[]" multiple required
                                                   @change="filesAreValid = validateFiles($event.target.files)">
                                        </div>

                                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('button.import') }}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md">
                    <h3>{{ __('imports.text.import_pdfs') }}</h3>

                    <div class="row">
                        <div class="col-md-8">
                            <form id="import-pdfs-form" method="post" action="" enctype="multipart/form-data" @submit.prevent="importFiles('import-pdfs-form')">
                                <div class="form-group">
                                    <label for="pdfs">{{ __('imports.label.select_files') }}</label><br>
                                    <input type="file" id="pdfs" class="form-control" name="pdf-files[]" accept="application/pdf" multiple required
                                           @change="filesAreValid = validateFiles($event.target.files)">
                                </div>
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="detect-hotspots" v-model="detectHotspots">
                                        <label class="custom-control-label" for="detect-hotspots">Detect hotspots</label>
                                    </div>
                                </div>

                                <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('button.import') }}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <hr>

            <div class="row">
                <div :class="['col-md' + (hasPermission('import_translations_sap') ? '' : '-6')]">
                    <h3>{{ __('imports.text.import_translations') }}</h3>

                    <div class="row">
                        <div class="col-md-8">
                            <form id="import-translations" method="post" action="" enctype="multipart/form-data" @submit.prevent="importFiles('import-translations')">
                                <div class="form-group">
                                    <label for="translation-file">{{ __('imports.label.select_files') }}</label><br>
                                    <input type="file" id="translation-file" class="form-control" name="translation-files"
                                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" required
                                           @change="filesAreValid = validateFiles($event.target.files)">
                                </div>

                                <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('button.import') }}</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-md" v-if="hasPermission('import_translations_sap')">
                    <h3>{{ __('imports.text.import_translations_sap') }}</h3>

                    <div class="row">
                        <div class="col-md-8">
                            <form id="import-translations-sap" method="post" action="" enctype="multipart/form-data"
                                  @submit.prevent="importFiles('import-translations-sap', sapOptions)">
                                <div class="form-group">
                                    <label for="translation-file-sap">{{ __('imports.label.select_files') }}</label><br>
                                    <input type="file" id="translation-file-sap" class="form-control" name="translation-files-sap"
                                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" required
                                           @change="filesAreValid = validateFiles($event.target.files)">
                                </div>

                                <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading" @click="sapOptions = {}">{{ __('button.import') }}</button>
                                <button type="submit" class="btn btn-secondary btn-sm" :disabled="globalVars.isLoading" @click="sapOptions = { side_by_side: true, non_translated: true }">
                                    {{ __('imports.button.side_by_side.non_translated') }}
                                </button>
                                <button type="submit" class="btn btn-secondary btn-sm" :disabled="globalVars.isLoading" @click="sapOptions = { side_by_side: true, difference: true }">
                                    {{ __('imports.button.side_by_side.difference') }}
                                </button>
                            </form>
                        </div>
                    </div>

                    <side-by-side-comparison-modal :data="sideBySideData"></side-by-side-comparison-modal>
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="col">
                    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                        <div class="row" v-show="errors != null && errors.length > 0">
                            <div class="col">
                                <h3 class="d-inline-block">{{ __('imports.text.messages') }}</h3>
                                <button type="button" class="btn btn-primary btn-sm" @click="errors = []">{{ __('imports.text.close_messages') }}</button>
                            </div>
                        </div>
                    </transition>

                    <div class="row">
                        <div class="col">
                            <list-staggered tag="div" :staggering-delay="1000">
                                <error-message v-for="(error, index) in errors" :key="index" :error="error" enter-animation="fadeInLeft"
                                               leave-animation="fadeOutLeft"></error-message>
                            </list-staggered>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from "../components/ErrorMessage";
    import OcrProcessUtil from '../components/util/OcrProcessUtil.vue';
    import ListStaggered from "../transitions/ListStaggered";
    import SideBySideComparisonModal from "../components/modals/SideBySideComparisonModal";
    import {EventBus} from "../eventbus";

    export default {
        components: {
            ErrorMessage,
            ListStaggered,
            SideBySideComparisonModal,
        },
        data() {
            return {
                errors: [],
                filesAreValid: false,
                sapOptions: {},
                sideBySide: false,
                compareNonTranslated: false,
                compareDifference: false,
                sideBySideData: null,
                detectHotspots: true,
            }
        },
        methods: {
            /**
             * Import files according to the formID
             *
             * @param formID
             * @param options Available options: {
             *     side_by_side: If true, will compare the translations in the database with the translations in the imported file.
             *     non_translated: If true, will return all the translations of the products that are not translated (a.k.a. NULL).
             *     difference: If true, will return all the translations that have changed, compared to the imported file.
             * }
             */
            importFiles(formID, options = {}) {
                this.errors = [];
                this.globalVars.globalError = new _Error();

                if (formID && this.filesAreValid) {
                    let formData = new FormData(document.getElementById(formID));
                    let params = {};
                    if (!_.isEmpty(options)) {
                        params = options;
                    }

                    axios({
                        method: 'post',
                        url: '/api/imports',
                        data: formData,
                        params: params,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.errors = response.data.error;

                                if (!_.isNil(response.data.result) && !_.isEmpty(options) && options.hasOwnProperty('side_by_side')) {
                                    this.sideBySideData = response.data.result;
                                    EventBus.$emit('toggle-side-by-side-modal');
                                }

                                if (formID === 'import-pdfs-form' && this.detectHotspots) {
                                    let figures = response.data.result;

                                    OcrApi.createOcrProcess(formData).then(response => {
                                        this.errors = this.errors.concat(response.data.error);

                                        for (let ocrProcess of response.data.result) {
                                            let figure = figures.find(f => f.code === ocrProcess.name);

                                            if (!_.isNil(figure)) {
                                                OcrProcessUtil.methods.linkOcrProcessToFigure(figure.id, ocrProcess)
                                                    .then(response => {
                                                        this.globalVars.globalError = this.createErrorFromRequest(response);
                                                    })
                                                    .catch(error => {
                                                        this.globalVars.globalError = this.createErrorFromRequest(error);
                                                    });
                                            }
                                        }
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            this.globalVars.globalError = this.createErrorFromRequest(error);
                        });
                } else {
                    this.globalVars.globalError = new _Error(Status.INFO, 0,
                        'De geselecteerde bestanden zijn te groot. Maximaal ' + UPLOAD_MAX_FILESIZE / 1000 / 1000 + 'MB toegestaan.');
                }
            },
            validateFiles(files) {
                if (!_.isNil(files) && files instanceof FileList) {
                    let totalSize = 0;

                    for (let file of files) {
                        totalSize += file.size;
                    }

                    if (totalSize < UPLOAD_MAX_FILESIZE) {
                        return true;
                    }
                }

                return false;
            },
        }
    }
</script>
