<template>
    <div class="modal fade" id="group-info-modal" tabindex="-1" role="dialog" aria-labelledby="product-info-modal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ theModelGroup.group.number }}: {{ theModelGroup.group.name }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="group-form" @submit.prevent="saveGroup('group-form')">
                    <div class="modal-body">
                        <fieldset>
                            <legend>{{ __('model.group.name') | capitalize }} {{ __('model.group.attr.image') }}</legend>

                            <div class="form-group">
                                <label for="group-image">{{ __('model.group.attr.image') | capitalize }}</label>
                                <input type="file" id="group-image" class="form-control" name="image" accept="image/jpeg,image/png" :disabled="globalVars.isLoading" required>
                            </div>

                            <div class="form-group" v-if="theModelGroup.image != null">
                                <label>{{ __('text.current_image') | capitalize }}</label>
                                <div class="row">
                                    <div class="col-8">
                                        <div
                                            v-viewer="{
                                                 fullscreen: false,
                                                 next: false,
                                                 rotatable: false,
                                                 scalable: false,
                                                 zoomRatio: 0.25,
                                                 tooltip: false,
                                            }">
                                            <img :src="theModelGroup.image" class="w-100 hover-pointer">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <confirm-buttons @confirmed="deleteImage"></confirm-buttons>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <error-message :error="error" :timeout="true" classes="mt-3"></error-message>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">
                            {{ __('button.save', {model: __('model.group.name')}) | capitalize }} <i v-show="globalVars.isLoading" class="fas fa-circle-notch fa-spin"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from "../ErrorMessage";
    import ConfirmButtons from "../util/ConfirmButtons";

    export default {
        components: {
            ErrorMessage,
            ConfirmButtons,
        },
        data() {
            return {
                error: new _Error(),
                theModelGroup: new ModelGroup(),
            }
        },
        props: {
            modelGroup: Object,
        },
        watch: {
            modelGroup: function () {
                this.resetForm('group-form');

                this.theModelGroup = this.modelGroup;
            },
        },
        methods: {
            saveGroup(formID) {
                if (this.theModelGroup.model_id > 0 && this.theModelGroup.group_id > 0) {
                    let formData = new FormData(document.getElementById(formID));

                    axios({
                        method: 'post',
                        url: `/api/models/${this.theModelGroup.model_id}/groups/${this.theModelGroup.group_id}`,
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                        .then(response => {
                            this.error = response.data.error;
                            this.theModelGroup = response.data.result;
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        });
                }
            },
            deleteImage() {
                if (this.theModelGroup.model_id > 0 && this.theModelGroup.group_id > 0) {
                    axios.delete(`/api/models/${this.theModelGroup.model_id}/groups/${this.theModelGroup.group_id}/image`)
                        .then(response => {
                            this.error = response.data.error;
                            this.theModelGroup = response.data.result;
                        });
                }
            }
        },
    }
</script>
