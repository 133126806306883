<template>
    <div class="modal fade" id="copy-group-modal" tabindex="-1" role="dialog" aria-labelledby="copy-group-modal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('copy_groups.header') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="copy-group-form" action="" method="post" @submit.prevent="copyGroups">
                    <div class="modal-body">
                        <error-message :error="error"></error-message>

                        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                            <div class="form-group" v-if="parentModels != null && parentModels.length > 0">
                                <label for="select-model-to">{{ __('copy_groups.label.copy_to') }}</label>
                                <select id="select-model-to" class="form-control" required
                                        v-model="modelToID" @change="updateFromModels(parentModels, modelToID)">
                                    <option value="0" disabled>{{ __('text.select_item') }}</option>
                                    <option v-for="{ id, number, name } in parentModels" :value="id">
                                        {{ number }}: {{ name }}
                                    </option>
                                </select>
                            </div>
                        </transition>

                        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                            <div class="form-group" v-if="parentModels != null && parentModels.length > 0">
                                <label for="select-model-from">{{ __('copy_groups.label.copy_from') }}</label>
                                <select id="select-model-from" class="form-control" required
                                        v-model="modelFromID" @change="filterAlreadyLinkedGroups(parentModels, modelToID, modelFromID)">
                                    <option value="0" disabled>{{ __('text.select_item') }}</option>
                                    <option v-for="{ id, number, name } in filteredModelsFrom" :value="id">
                                        {{ number }}: {{ name }}
                                    </option>
                                </select>
                            </div>
                        </transition>

                        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                            <div v-if="modelToID === 0" key="select-model-to">
                                {{ __('copy_groups.text.select_model_to') }}
                            </div>
                            <div v-else-if="modelFromID === 0" key="select-model-from">
                                {{ __('copy_groups.text.select_model_from') }}
                            </div>
                            <div key="filtered-groups" class="form-group" v-else-if="filteredGroups != null && filteredGroups.length > 0">
                                <label>{{ __('model.group.name', { count: 2 }) | capitalize }}</label>
                                <transition-group name="list-complete" tag="div">
                                    <div class="custom-control custom-checkbox list-complete-item" v-for="group in filteredGroups" :key="group.id">
                                        <input type="checkbox" :id="'copy-check-' + group.id" class="custom-control-input" name="select-group"
                                               v-model="selectedGroupIDs" :value="group.id">
                                        <label :for="'copy-check-' + group.id" class="custom-control-label">{{ group.number }}: {{ group.name }}</label>
                                    </div>
                                </transition-group>
                            </div>
                            <div key="no-filtered-groups" v-else>
                                {{ __('link_groups.text.already_linked') }}
                            </div>
                        </transition>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('button.close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('copy_groups.button.save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from '../../eventbus';
    import ErrorMessage from "../ErrorMessage";

    export default {
        components: {
            ErrorMessage,
        },
        data() {
            return {
                filteredGroups: [],
                filteredModelsFrom: [],
                modelToID: 0,
                modelFromID: 0,
                selectedGroupIDs: [],
                error: new _Response(),
                StatusMapper: StatusMapper,
            }
        },
        props: {
            parentModels: Array,
        },
        created() {
            let self = this;

            EventBus.$on('model-selected', (modelID) => {
                if (!_.isNil(modelID) && _.isInteger(modelID)) {
                    this.modelToID = modelID;
                    this.updateFromModels(this.parentModels, modelID);
                }
            });

            $(function () {
                $('#copy-group-modal').on('hidden.bs.modal', () => {
                    self.error = new _Error();
                    self.selectedGroupIDs = [];
                    self.resetForm('copy-group-form');
                });
            });
        },
        destroyed() {
            EventBus.$off('model-selected');
        },
        methods: {
            copyGroups() {
                this.error = new _Error();

                if (!_.isNil(this.modelToID) && _.isNumber(this.modelToID) && !_.isNil(this.modelFromID) && _.isNumber(this.modelFromID)
                    && !_.isNil(this.selectedGroupIDs) && _.isArray(this.selectedGroupIDs) && this.selectedGroupIDs.length > 0) {
                    axios
                        .put('/api/models/' + this.modelToID + '/groups', {
                            model_from_id: this.modelFromID,
                            group_ids: this.selectedGroupIDs,
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;
                                this.selectedGroupIDs = [];

                                if (response.status === HTTP_CREATED) {
                                    this.filteredGroups = [];
                                    this.resetForm('copy-group-form');

                                    this.emitEvent('get-models', {
                                        selectedModelID: this.modelToID,
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            filterAlreadyLinkedGroups(models, modelToID, modelFromID) {
                if (!_.isNil(modelFromID) && _.isInteger(modelFromID)) {
                    let selectedTo = this.getItemFromArrayByID(models, modelToID);
                    let selectedFrom = this.getItemFromArrayByID(models, modelFromID);
                    if (!_.isNil(selectedTo) && !_.isNil(selectedFrom)) {
                        this.filteredGroups = selectedFrom.groups.filter(g => {
                            let curGroup = _.find(selectedTo.groups, {id: g.id});
                            return !_.isNil(curGroup) ? g.id !== curGroup.id : true;
                        });
                    }

                    this.selectedGroupIDs = [];
                    this.modelFromID = selectedFrom.id;
                }
            },
            updateFromModels(models, modelID) {
                if (!_.isNil(modelID) && _.isInteger(modelID) && (!_.isNil(models))) {
                    this.filteredModelsFrom = models.filter(g => {
                        if (g.id !== modelID) {
                            return true;
                        } else {
                            this.modelFromID = 0;
                        }
                    });
                }
            }
        }
    }
</script>
