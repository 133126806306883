<template>
    <div class="row">
        <div class="col">
            {{ __('page_not_found.header') }}
        </div>
    </div>
</template>

<script>
    export default {}
</script>
