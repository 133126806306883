<template>
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col-md col-xl-4">
                    <div class="row">
                        <div class="col">
                            <h3 class="d-inline-block">{{ __('model.model.name') | capitalize }}</h3>
                            <button type="button" class="btn btn-primary btn-sm d-inline-block"
                                    :title="__('button.reload', { model: __('model.model.name', { count: 2 }) }) | capitalize"
                                    @click="getModels" :disabled="globalVars.isLoading">
                                <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading }"></i>
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col scrollable scrollable-sm">
                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                                <div key="models-found" v-if="models != null && models.length > 0">
                                    <div class="list-group">
                                        <button v-for="model in models" type="button" class="list-group-item list-group-item-action pr-5"
                                                :class="{ 'active': selectedModelID === model.id }" @click="onModelClicked(model)">
                                            {{ model.number }}: {{ model.name }}
                                            <i v-if="modelOrAnyChildHasUpdated(model)" class="fa fa-circle text-success model-has-updated-icon"></i>
                                        </button>
                                    </div>
                                </div>
                                <div key="models_loading" v-else-if="globalVars.isLoading">
                                    {{ __('text.loading_models', {model: __('model.model.name', {count: 2})}) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                                </div>
                                <div key="models_else" v-else>
                                    {{ __('text.no_models_found', {model: __('model.model.name', {count: 2})}) | capitalize }}
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <div class="col-md col-xl-3">
                    <div class="row">
                        <div class="col">
                            <h3 class="d-inline-block">{{ __('model.group.name') | capitalize }}</h3>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col scrollable scrollable-sm">
                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                                <div v-if="groups != null && groups.length > 0" key="groups_not_null">
                                    <transition-group name="list-complete" tag="div">
                                        <div class="custom-control custom-checkbox list-complete-item" v-for="group in groups" :key="group.id">
                                            <input type="checkbox" :id="'check-' + group.id" class="custom-control-input" name="select-group"
                                                   v-model="selectedGroupIDs" :value="group.id">
                                            <label :for="'check-' + group.id" class="custom-control-label w-100">
                                                {{ group.number }}: {{ group.name }}
                                                <i v-if="groupHasUpdated(group)" class="fa fa-circle text-success group-has-updated-icon"></i>
                                            </label>
                                        </div>
                                    </transition-group>
                                </div>
                                <div v-else-if="selectedModelID === 0" key="no-model-selected">
                                    {{ __('text.no_model_selected', {model: __('model.model.name')}) | capitalize }}
                                </div>
                                <div v-else key="no-groups">
                                    {{ __('text.no_models_found', {model: __('model.group.name', {count: 2})}) | capitalize }}
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <div class="col-xl mt-3">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a href="#export-excel" class="nav-link active" data-toggle="tab">{{ __('exports.title.button.export_to_format', {format: 'Excel'}) }}</a>
                        </li>
                        <li class="nav-item">
                            <a href="#export-xml" class="nav-link" data-toggle="tab">{{ __('exports.title.button.export_to_format', {format: 'XML'}) }}</a>
                        </li>
                        <li class="nav-item">
                            <a href="#export-shop" class="nav-link" data-toggle="tab">{{ __('exports.title.button.export_to_format', {format: 'Shop'}) }}</a>
                        </li>
                        <li v-if="hasPermission(`export.${EXPORT_FORMATS.magento_shops}`)" class="nav-item">
                            <a href="#export-magento-shops" class="nav-link" data-toggle="tab">{{ __('exports.title.button.export_to_format', {format: 'Magento shops'}) }}</a>
                        </li>
                        <li class="nav-item">
                            <a href="#export-usb" class="nav-link" data-toggle="tab">{{ __('exports.title.button.export_to_format', {format: 'USB'}) }}</a>
                        </li>
                        <li class="nav-item">
                            <a href="#export-e2v-shop" class="nav-link" data-toggle="tab">{{ __('exports.title.button.export_to_format', {format: 'E2V Shop'}) }}</a>
                        </li>
                        <li class="nav-item">
                            <a href="#export-e2v-usb" class="nav-link" data-toggle="tab">{{ __('exports.title.button.export_to_format', {format: 'E2V USB'}) }}</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div id="export-excel" class="tab-pane fade show active">
                            <button type="button" class="btn btn-primary d-inline-block mt-3"
                                    :title="selectedModelID === 0 ? __('exports.title.button.select_model_or_group') : __('exports.title.button.export_model')"
                                    @click="exportModel(EXPORT_FORMATS.xlsx, MODEL_NAMES.models, selectedModelID, { groups: selectedGroupIDs })"
                                    :disabled="globalVars.isLoading || selectedModelID === 0">
                                {{ __('exports.title.button.export_to_format', {format: 'Excel'}) }} <i class="fas fa-file-export"></i>
                            </button>
                        </div>

                        <div id="export-xml" class="tab-pane fade">
                            <fieldset>
                                <legend>{{ __('label.settings') }}</legend>

                                <div class="form-group">
                                    <label>{{ __('label.language_plural') }}</label><br/>
                                    <draggable tag="div" v-model="selectedLanguages">
                                        <div class="custom-control custom-checkbox" v-for="lang of selectedLanguages">
                                            <input type="checkbox" :id="lang.code" class="custom-control-input" name="xml-languages"
                                                   v-model="lang.selected" :disabled="globalVars.isLoading">
                                            <label class="custom-control-label" :for="lang.code">{{ lang.code.toUpperCase() }}</label>
                                        </div>
                                    </draggable>
                                    <small class="text-muted">{{ __('text.drag_to_reorder') }}</small>
                                </div>
                            </fieldset>

                            <button type="button" class="btn btn-primary d-inline-block mt-3"
                                    :title="selectedModelID === 0 ? __('exports.title.button.select_model_or_group') : __('exports.title.button.export_model')"
                                    @click="exportModel(EXPORT_FORMATS.xml, MODEL_NAMES.models, selectedModelID, { groups: selectedGroupIDs, languages: selectedLanguagesCodes })"
                                    :disabled="globalVars.isLoading || selectedModelID === 0">
                                {{ __('exports.title.button.export_to_format', {format: 'XML'}) }} <i class="fas fa-file-export"></i>
                            </button>
                        </div>

                        <div id="export-shop" class="tab-pane fade">
                            <fieldset>
                                <legend>{{ __('label.settings') }}</legend>

                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>{{ __('label.language_plural') }}</label><br/>
                                            <draggable tag="div" v-model="selectedLanguages">
                                                <div class="custom-control custom-checkbox" v-for="lang of selectedLanguages">
                                                    <input type="checkbox" :id="lang.code" class="custom-control-input" name="xml-languages"
                                                           v-model="lang.selected" :disabled="globalVars.isLoading">
                                                    <label class="custom-control-label" :for="lang.code">{{ lang.code.toUpperCase() }}</label>
                                                </div>
                                            </draggable>
                                            <small class="text-muted">{{ __('text.drag_to_reorder') }}</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>{{ __('label.column_plural') }}</label><br/>
                                            <div class="custom-control custom-checkbox" v-for="column of HOTSPOT_COLUMNS">
                                                <input type="checkbox" :id="`columns-${column}`" class="custom-control-input" name="xml-languages"
                                                       :value="column" v-model="selectedColumns" :disabled="globalVars.isLoading">
                                                <label class="custom-control-label" :for="`columns-${column}`">{{ column | capitalize }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <button type="button" class="btn btn-primary d-inline-block mt-3"
                                    :title="selectedModelID === 0 ? __('exports.title.button.select_model_or_group') : __('exports.title.button.export_model')"
                                    @click="exportModel(
                                        EXPORT_FORMATS.shop, MODEL_NAMES.models, selectedModelID,{
                                            groups: selectedGroupIDs,
                                            languages: selectedLanguagesCodes,
                                            columns: selectedColumns
                                        }
                                    )"
                                    :disabled="globalVars.isLoading || selectedModelID === 0">
                                {{ __('exports.title.button.export_to_format', {format: 'Shop'}) }} <i class="fas fa-file-export"></i>
                            </button>
                        </div>

                        <div v-if="hasPermission(`export.${EXPORT_FORMATS.magento_shops}`)" id="export-magento-shops" class="tab-pane fade">
                            <fieldset>
                                <legend>{{ __('label.settings') }}</legend>

                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>{{ __('label.language_plural') }}</label>
                                            <draggable tag="div" v-model="selectedLanguages">
                                                <div class="custom-control custom-checkbox" v-for="lang of selectedLanguages">
                                                    <input type="checkbox" :id="lang.code" class="custom-control-input" name="xml-languages"
                                                           v-model="lang.selected" :disabled="globalVars.isLoading">
                                                    <label class="custom-control-label" :for="lang.code">{{ lang.code.toUpperCase() }}</label>
                                                </div>
                                            </draggable>
                                            <small class="text-muted">{{ __('text.drag_to_reorder') }}</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>{{ __('label.column_plural') }}</label>
                                            <div class="custom-control custom-checkbox" v-for="column of HOTSPOT_COLUMNS">
                                                <input type="checkbox" :id="`columns-${column}`" class="custom-control-input" name="xml-languages"
                                                       :value="column" v-model="selectedColumns" :disabled="globalVars.isLoading">
                                                <label class="custom-control-label" :for="`columns-${column}`">{{ column | capitalize }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="model-name">{{ __('exports.magento_shops.label.model_name') }}</label>
                                    <input type="text" id="model-name" class="form-control" v-model="modelName">
                                </div>

                                <div class="form-group">
                                    <label>{{ __('model.shop.name_plural') | capitalize }}</label>
                                    <MagentoShopSelector v-model="selectedMagentoShops"></MagentoShopSelector>
                                </div>
                            </fieldset>

                            <button type="button" class="btn btn-primary d-inline-block mt-3"
                                    :title="selectedModelID === 0 ? __('exports.title.button.select_model_or_group') : __('exports.title.button.export_model')"
                                    @click="exportModel(EXPORT_FORMATS.magento_shops, MODEL_NAMES.models, selectedModelID, {
                                        groups: selectedGroupIDs,
                                        languages: selectedLanguagesCodes,
                                        columns: selectedColumns,
                                        model_name: modelName,
                                        shops: selectedMagentoShops,
                                    })"
                                    :disabled="globalVars.isLoading || selectedModelID === 0">
                                {{ __('exports.title.button.export_to_format', {format: 'Magento shops'}) }} <i class="fas fa-file-export"></i>
                            </button>
                        </div>

                        <div id="export-usb" class="tab-pane fade">
                            <fieldset>
                                <legend>{{ __('label.settings') }}</legend>

                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>{{ __('label.language_plural') }}</label><br/>
                                            <draggable tag="div" v-model="selectedLanguages">
                                                <div class="custom-control custom-checkbox" v-for="lang of selectedLanguages">
                                                    <input type="checkbox" :id="lang.code" class="custom-control-input" name="xml-languages"
                                                           v-model="lang.selected" :disabled="globalVars.isLoading">
                                                    <label class="custom-control-label" :for="lang.code">{{ lang.code.toUpperCase() }}</label>
                                                </div>
                                            </draggable>
                                            <small class="text-muted">{{ __('text.drag_to_reorder') }}</small>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>{{ __('label.column_plural') }}</label><br/>
                                            <div class="custom-control custom-checkbox" v-for="column of HOTSPOT_COLUMNS">
                                                <input type="checkbox" :id="`columns-${column}`" class="custom-control-input" name="xml-languages"
                                                       :value="column" v-model="selectedColumns" :disabled="globalVars.isLoading">
                                                <label class="custom-control-label" :for="`columns-${column}`">{{ column | capitalize }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <button type="button" class="btn btn-primary d-inline-block mt-3"
                                    :title="selectedModelID === 0 ? __('exports.title.button.select_model_or_group') : __('exports.title.button.export_model')"
                                    @click="exportModel(
                                        EXPORT_FORMATS.usb, MODEL_NAMES.models, selectedModelID,{
                                            groups: selectedGroupIDs,
                                            languages: selectedLanguagesCodes,
                                            columns: selectedColumns
                                        }
                                    )"
                                    :disabled="globalVars.isLoading || selectedModelID === 0">
                                {{ __('exports.title.button.export_to_format', {format: 'USB'}) }} <i class="fas fa-file-export"></i>
                            </button>
                        </div>

                        <div id="export-e2v-shop" class="tab-pane fade">
                            <fieldset>
                                <legend>{{ __('label.settings') }}</legend>

                                <div class="form-group">
                                    <label>{{ __('revision_number') }}</label><br/>
                                    <input type="text" class="form-control" v-model="revisionNumber">
                                </div>

                                <div class="form-group">
                                    <label>{{ __('label.language_plural') }}</label><br/>
                                    <draggable tag="div" v-model="selectedLanguages">
                                        <div class="custom-control custom-checkbox" v-for="lang of selectedLanguages">
                                            <input type="checkbox" :id="lang.code" class="custom-control-input" name="xml-languages"
                                                   v-model="lang.selected" :disabled="globalVars.isLoading">
                                            <label class="custom-control-label" :for="lang.code">{{ lang.code.toUpperCase() }}</label>
                                        </div>
                                    </draggable>
                                    <small class="text-muted">{{ __('text.drag_to_reorder') }}</small>
                                </div>
                            </fieldset>

                            <button type="button" class="btn btn-primary d-inline-block mt-3"
                                    :title="selectedModelID === 0 ? __('exports.title.button.select_model_or_group') : __('exports.title.button.export_model')"
                                    @click="exportModel(EXPORT_FORMATS.e2v_shop, MODEL_NAMES.models, selectedModelID, {
                                            groups: selectedGroupIDs,
                                            languages: selectedLanguagesCodes,
                                            revision_number: revisionNumber,
                                        }
                                    )"
                                    :disabled="globalVars.isLoading || selectedModelID === 0">
                                {{ __('exports.title.button.export_to_format', {format: 'E2V Shop'}) }} <i class="fas fa-file-export"></i>
                            </button>
                        </div>

                        <div id="export-e2v-usb" class="tab-pane fade">
                            <fieldset>
                                <legend>{{ __('label.settings') }}</legend>

                                <div class="form-group">
                                    <label>{{ __('revision_number') }}</label><br/>
                                    <input type="text" class="form-control" v-model="revisionNumber">
                                </div>

                                <div class="form-group">
                                    <label>{{ __('label.language_plural') }}</label><br/>
                                    <draggable tag="div" v-model="selectedLanguages">
                                        <div class="custom-control custom-checkbox" v-for="lang of selectedLanguages">
                                            <input type="checkbox" :id="lang.code" class="custom-control-input" name="xml-languages"
                                                   v-model="lang.selected" :disabled="globalVars.isLoading">
                                            <label class="custom-control-label" :for="lang.code">{{ lang.code.toUpperCase() }}</label>
                                        </div>
                                    </draggable>
                                    <small class="text-muted">{{ __('text.drag_to_reorder') }}</small>
                                </div>
                            </fieldset>

                            <button type="button" class="btn btn-primary d-inline-block mt-3"
                                    :title="selectedModelID === 0 ? __('exports.title.button.select_model_or_group') : __('exports.title.button.export_model')"
                                    @click="exportModel(EXPORT_FORMATS.e2v_usb, MODEL_NAMES.models, selectedModelID, {
                                            groups: selectedGroupIDs,
                                            languages: selectedLanguagesCodes,
                                            revision_number: revisionNumber,
                                        }
                                    )"
                                    :disabled="globalVars.isLoading || selectedModelID === 0">
                                {{ __('exports.title.button.export_to_format', {format: 'E2V USB'}) }} <i class="fas fa-file-export"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <hr/>

            <div class="row">
                <div class="col-md-4 mb-3">
                    <h3>{{ __('model.translation.name', {count: 2}) | capitalize }}</h3>

                    <div class="form-group">
                        <label for="language-to-export">{{ __('label.language') }}</label>
                        <select id="language-to-export" class="form-control" v-model="languageToExport" required :disabled="globalVars.isLoading">
                            <option value="">{{ __('text.select_item') }}</option>
                            <option v-for="LANGUAGE of LANGUAGES" :value="LANGUAGE">{{ LANGUAGE.toUpperCase() }}</option>
                        </select>
                    </div>

                    <button type="button" class="btn btn-primary"
                            @click="exportModel(EXPORT_FORMATS.xlsx, MODEL_NAMES.translations, null, { languages: [globalVars.lang, languageToExport] })"
                            :disabled="globalVars.isLoading || languageToExport === ''">
                        {{ __('exports.title.export_translations') }} <i class="fas fa-file-export"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from '../eventbus';
    import draggable from 'vuedraggable';
    import MagentoShopSelector from '../components/util/MagentoShopSelector.vue';

    export default {
        components: {
            draggable,
            MagentoShopSelector,
        },
        data() {
            return {
                // Constants
                LANGUAGES: LANGUAGES,
                EXPORT_FORMATS: EXPORT_FORMATS,
                MODEL_NAMES: MODEL_NAMES,

                // General
                exportSettings: {},

                // Model data
                models: [],
                selectedModelID: 0,

                // Group data
                groups: [],
                selectedGroupIDs: [],

                // Language data
                selectedLanguages: [],
                languageToExport: '',

                // Column data
                HOTSPOT_COLUMNS: HOTSPOT_COLUMNS,
                selectedColumns: ['quantity', 'data', 'info'], // @TODO: Make a User-setting of this.

                // Export to E2V data
                revisionNumber: '',

                // Export to Magento 1.9 shops
                modelName: '',
                selectedMagentoShops: [],
            }
        },
        computed: {
            selectedLanguagesCodes: function () {
                return this.selectedLanguages
                    .filter((lang) => {
                        return lang.selected;
                    })
                    .map((lang) => {
                        return lang.code;
                    });
            },
        },
        created() {
            this.getModels();

            let activeLanguages = ['nl', 'en', 'de', 'fr']; // @TODO: Make a User-setting of this.
            for (let lang of LANGUAGES) {
                this.selectedLanguages.push({
                    code: lang,
                    selected: activeLanguages.indexOf(lang) > -1,
                });
            }

            EventBus.$on('model-exported', args => {
                if (!_.isEmpty(args)) {
                    if (!_.isNil(args.modelID) && !_.isNil(args.data.mark_as_exported) && args.data.mark_as_exported) {
                        let model = this.getItemFromArrayByID(this.models, args.modelID);
                        if (!_.isNil(model)) {
                            model.hasUpdated = false;

                            for (let group of model.groups) {
                                group.pivot.hasUpdated = false;
                            }
                        }
                    }
                }
            });
        },
        destroyed() {
            EventBus.$off('model-exported');
        },
        methods: {
            getModels() {
                this.models = this.groups = this.selectedGroupIDs = [];
                this.selectedModelID = 0;

                axios
                    .get('/api/models', {
                        params: {
                            with_groups: '',
                        }
                    })
                    .then(response => {
                        if (!_.isEmpty(response)) {
                            if (response.status === HTTP_OK) {
                                this.models = response.data.result;
                            }
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
                ;
            },
            getModelExportSettings(model) {
                axios
                    .get(`/api/models/${model.id}/export-settings`)
                    .then(response => {
                        if (!_.isEmpty(response)) {
                            if (response.status === HTTP_OK) {
                                this.exportSettings = response.data.result;

                                if (this.exportSettings?.hasOwnProperty(EXPORT_FORMATS.magento_shops)) {
                                    const magentoShopsExportSettings = this.exportSettings[EXPORT_FORMATS.magento_shops];

                                    this.modelName = magentoShopsExportSettings.model_name || model.name;
                                    this.selectedMagentoShops = magentoShopsExportSettings.shops;
                                } else {
                                    this.modelName = model.name;
                                    this.selectedMagentoShops = [];
                                }
                            }
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
            },

            modelOrAnyChildHasUpdated(model) {
                if (_.isObject(model) && !_.isNil(model.groups)) {
                    for (let group of model.groups) {
                        if (this.groupHasUpdated(group)) {
                            return true;
                        }
                    }
                }

                return !!model.hasUpdated;
            },
            groupHasUpdated(group) {
                if (!_.isNil(group)) {
                    return group.pivot ? !!group.pivot.hasUpdated : false;
                }
            },

            // Events
            onModelClicked(model) {
                this.selectedModelID = model.id;

                this.groups = model.groups;
                this.selectedGroupIDs = [];

                this.getModelExportSettings(model);
            },
        }
    }
</script>
