<template>
    <div class="row mb-3">
        <div class="col">
            <create-model-modal></create-model-modal>

            <model-group-info-modal :model-group="selectedModelGroup"></model-group-info-modal>

            <link-groups-modal :parent-models="models"></link-groups-modal>
            <create-group-modal :parent-models="models"></create-group-modal>
            <copy-group-modal :parent-models="models"></copy-group-modal>

            <create-figure-modal :selected-model-id="selectedModelID" :selected-model-number="selectedModelNumber" :selected-group-number="selectedGroupNumber"
                                 :parent-figures="clonedFigures" :parent-groups="groups">
            </create-figure-modal>

            <link-products-modal :parent-figures="figures"></link-products-modal>

            <FilePreviewer id="file-previewer" :url="selectedFigure != null ? selectedFigure.pdf_url : ''"></FilePreviewer>

            <error-message :error="error" :timeout="true" :toast="true" positioning="floating" enter-animation="fadeInLeft" leave-animation="fadeOutLeft"
                           classes="mt-3"></error-message>

            <div class="row">
                <div class="col-md">
                    <div class="row">
                        <div class="col">
                            <h3 class="d-inline-block">{{ __('model.model.name') | capitalize }}</h3>
                            <button type="button" class="btn btn-primary btn-sm d-inline-block"
                                    :title="__('button.reload', { model: __('model.model.name', { count: 2 }) }) | capitalize"
                                    @click="getModels" :disabled="globalVars.isLoading">
                                <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading }"></i>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm d-inline-block" :title="__('maintenance.title.button.model.create')"
                                    data-toggle="modal" data-target="#create-model-modal">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col scrollable scrollable-sm">
                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                                <div v-if="models != null && models.length > 0" key="models-found">
                                    <div class="list-group list-group-item-lg">
                                        <div v-for="model in models" tabindex="0"
                                             class="list-group-item list-group-item-action hover hover-pointer" :class="{ 'active': selectedModelID === model.id }"
                                             @click="getGroups(model.id)" @keydown.space="getGroups(model.id)"
                                             @mouseover="$set(model, 'hovering', true)" @mouseleave="$set(model, 'hovering', false)"
                                             @focusin="$set(model, 'hovering', true)" @focusout="$set(model, 'hovering', false)">
                                            <div class="row">
                                                <div class="col-8">
                                                    <!-- Model info -->
                                                    <transition enter-active-class="animated fadeInRight position-absolute"
                                                                leave-active-class="animated fadeOutRight position-absolute">
                                                        <span v-show="!model.edit">{{ model.number }}: {{ model.name }}</span>
                                                    </transition>

                                                    <!-- Edit Model info-->
                                                    <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                        <div class="input-group" v-show="model.edit">
                                                            <input type="text" class="form-control"
                                                                   :title="__('title.input.model_field', {
                                                                               field: __('model.model.attr.name'),
                                                                               model: __('model.model.name')
                                                                           }) | capitalize"
                                                                   :placeholder="__('model.model.attr.name') | capitalize"
                                                                   v-model="model.name" @keydown.enter="saveModel(model.id)" @keydown.space.stop @click.stop>
                                                            <div class="input-group-append">
                                                                <button type="button" class="btn btn-success btn-sm"
                                                                        :title="__('button.save', { model: __('model.model.name') }) | capitalize"
                                                                        @click.stop="saveModel(model.id)" :disabled="globalVars.isLoading">
                                                                    <i class="fas fa-save"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </transition>
                                                </div>
                                                <div class="col">
                                                    <div class="float-right">
                                                        <model-crud-buttons :model-type="'model'" :model="model" :remove="false"></model-crud-buttons>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="globalVars.isLoading" key="loading-models">
                                    {{ __('text.loading_models', { model: __('model.model.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                                </div>
                                <div v-else key="no-models">
                                    {{ __('text.no_models_found', { model: __('model.model.name', { count: 2 }) }) | capitalize }}
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <div class="col-md">
                    <div class="row">
                        <div class="col">
                            <h3 class="d-inline-block">{{ __('model.group.name') | capitalize }}</h3>
                            <button type="button" class="btn btn-primary btn-sm d-inline-block" :title="__('maintenance.title.button.group.link')"
                                    data-toggle="modal" data-target="#link-groups-to-figure-modal">
                                <i class="fas fa-link"></i>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm d-inline-block" :title="__('maintenance.title.button.group.copy')"
                                    data-toggle="modal" data-target="#copy-group-modal">
                                <i class="fas fa-copy"></i>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm d-inline-block" :title="__('maintenance.title.button.group.create')"
                                    data-toggle="modal" data-target="#create-group-modal">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col scrollable scrollable-sm">
                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                                <div v-if="groups != null && groups.length > 0" key="groups_not_null">
                                    <div class="list-group list-group-item-lg">
                                        <transition-group name="list-complete" tag="div">
                                            <div v-for="group in groups" :key="group.id" tabindex="0" @click="getFigures(group.id)" @keydown.space="getFigures(group.id)"
                                                 class="list-group-item list-group-item-action hover hover-pointer list-complete-item"
                                                 :class="{ 'active': selectedGroupID === group.id }"
                                                 @mouseover="$set(group, 'hovering', true)" @mouseleave="$set(group, 'hovering', false)"
                                                 @focusin="$set(group, 'hovering', true)" @focusout="$set(group, 'hovering', false)">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <transition enter-active-class="animated fadeInRight position-absolute"
                                                                    leave-active-class="animated fadeOutRight position-absolute">
                                                            <span v-show="!group.edit">{{ group.number }}: {{ group.name }}</span>
                                                        </transition>

                                                        <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                            <div class="input-group" v-show="group.edit">
                                                                <input type="text" class="form-control"
                                                                       :title="__('title.input.model_field', {
                                                                                   field: __('model.group.attr.name'),
                                                                                   model: __('model.group.name')
                                                                               }) | capitalize"
                                                                       :placeholder="__('model.group.attr.name') | capitalize"
                                                                       v-model="group.name" @keydown.enter="saveGroup(group.id)" @keydown.space.stop @click.stop>
                                                                <div class="input-group-append">
                                                                    <button type="button" class="btn btn-success btn-sm"
                                                                            :title="__('button.save', { model: __('model.group.name') }) | capitalize"
                                                                            @click.stop="saveGroup(group.id)" :disabled="globalVars.isLoading">
                                                                        <i class="fas fa-save"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </transition>
                                                    </div>
                                                    <div class="col">
                                                        <div class="float-right">
                                                            <model-crud-buttons
                                                                :model-type="'group'"
                                                                :model="group"
                                                                event="unlink-group"
                                                                @unlink-group="unlinkGroupFromModel(selectedModelID, group.id)"
                                                            >
                                                                <template #append>
                                                                    <button type="button" class="btn btn-info btn-sm"
                                                                            data-toggle="modal" data-target="#group-info-modal"
                                                                            @click="selectedModelGroup = {...group.pivot, group: group}">
                                                                        <i class="fas fa-info-circle"></i>
                                                                    </button>
                                                                </template>
                                                            </model-crud-buttons>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition-group>
                                    </div>
                                </div>
                                <div v-else-if="selectedModelID === 0" key="no-model-selected">
                                    {{ __('text.no_model_selected', { model: __('model.model.name') }) | capitalize }}
                                </div>
                                <div v-else key="no-groups">
                                    {{ __('text.no_models_found', { model: __('model.group.name', { count: 2 }) }) | capitalize }}
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <div class="col-md">
                    <div class="row">
                        <div class="col">
                            <h3 class="d-inline-block">{{ __('model.figure.name') | capitalize }}</h3>
                            <!-- @TODO: Don't reload models when deleting Figures (just like with deleting Groups) -->

                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                                <button type="button" class="btn btn-primary btn-sm d-inline-block" :title="__('maintenance.title.button.figure.create')"
                                        data-toggle="modal" data-target="#create-figure-modal" v-if="selectedModelID !== 0">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </transition>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col scrollable scrollable-sm">
                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                                <!-- @TODO: Animation is broken, removed it for now -->
                                <div key="figures-found" v-if="figures != null && figures.length > 0">
                                    <div class="list-group list-group-item-lg">
                                        <div v-for="figure in figures" :key="figure.id" tabindex="0"
                                             class="list-group-item list-group-item-action hover hover-pointer"
                                             :class="{ 'active': selectedFigureID === figure.id }"
                                             @click="getFigureProducts(figure.id)" @keydown.space="getFigureProducts(figure.id)"
                                             @mouseover="$set(figure, 'hovering', true)" @mouseleave="$set(figure, 'hovering', false)"
                                             @focusin="$set(figure, 'hovering', true)" @focusout="$set(figure, 'hovering', false)">
                                            <div class="row">
                                                <div class="col-8">
                                                    <transition enter-active-class="animated fadeInLeft position-absolute"
                                                                leave-active-class="animated fadeOutLeft position-absolute">
                                                        <span v-show="!figure.edit">{{ figure.number }}: {{ figure.code }} {{ figure.name ? ' - ' + figure.name : '' }}</span>
                                                    </transition>

                                                    <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                        <form :id="'save-figure-' + figure.id + '-form'" method="post" action="" @submit.prevent="saveFigure(figure.id)"
                                                              v-show="figure.edit">
                                                            <div class="form-inline">
                                                                <input type="text" class="form-control" required
                                                                       :title="__('title.input.model_field', {
                                                                                   field: __('model.figure.attr.code'),
                                                                                   model: __('model.figure.name')
                                                                               }) | capitalize"
                                                                       :placeholder="__('model.figure.attr.code') | capitalize"
                                                                       v-model="figure.code" @click.stop @keydown.space.stop>
                                                                <input type="text" class="form-control mr-2" required
                                                                       :title="__('title.input.model_field', {
                                                                                   field: __('model.figure.attr.name'),
                                                                                   model: __('model.figure.name')
                                                                               }) | capitalize"
                                                                       :placeholder="__('model.figure.attr.name') | capitalize"
                                                                       v-model="figure.name" @click.stop @keydown.space.stop>

                                                                <div class="form-group mt-3">
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" :id="'no-pre-post-' + figure.id" class="custom-control-input"
                                                                               :name="'pre_post_type_' + figure.id"
                                                                               v-model="figure.pre_post_type" :value="null"
                                                                               @change="figure.VIN = ''"
                                                                               @click.stop @keydown.space.stop @keydown.enter.stop>
                                                                        <label class="custom-control-label" :for="'no-pre-post-' + figure.id" @click.stop>
                                                                            {{ __('maintenance.label.figure.no_pre_post_situation') }}
                                                                        </label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" :id="'pre-' + figure.id" class="custom-control-input"
                                                                               :name="'pre_post_type_' + figure.id"
                                                                               v-model="figure.pre_post_type" :value="PRE_POST_TYPE.PRE"
                                                                               @click.stop @keydown.space.stop @keydown.enter.stop>
                                                                        <label class="custom-control-label" :for="'pre-' + figure.id" @click.stop>Pre</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" :id="'post-' + figure.id" class="custom-control-input"
                                                                               :name="'pre_post_type_' + figure.id"
                                                                               v-model="figure.pre_post_type" :value="PRE_POST_TYPE.POST"
                                                                               @click.stop @keydown.space.stop @keydown.enter.stop>
                                                                        <label class="custom-control-label" :for="'post-' + figure.id" @click.stop>Post</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" :id="'range-' + figure.id" class="custom-control-input"
                                                                               :name="'pre_post_type_' + figure.id"
                                                                               v-model="figure.pre_post_type" :value="PRE_POST_TYPE.RANGE"
                                                                               @click.stop @keydown.space.stop @keydown.enter.stop>
                                                                        <label class="custom-control-label" :for="'range-' + figure.id" @click.stop>Range</label>
                                                                    </div>
                                                                </div>

                                                                <div class="input-group">
                                                                    <div v-if="figure.pre_post_type === PRE_POST_TYPE.RANGE" class="input-group-prepend">
                                                                        <span class="input-group-text">From</span>
                                                                    </div>
                                                                    <input type="text" class="form-control"
                                                                           :required="figure.pre_post_type != null"
                                                                           :title="__('title.input.model_field', {
                                                                                       field: __('model.figure.attr.vin'),
                                                                                       model: __('model.figure.name')
                                                                                   }) | capitalize"
                                                                           :placeholder="__('model.figure.attr.vin') | capitalize"
                                                                           v-model="figure.VIN"
                                                                           @click.stop @keydown.space.stop>
                                                                    <div v-if="figure.pre_post_type === PRE_POST_TYPE.RANGE" class="input-group-append">
                                                                        <span class="input-group-text">To</span>
                                                                    </div>
                                                                    <input v-if="figure.pre_post_type === PRE_POST_TYPE.RANGE"
                                                                           type="text" class="form-control" required
                                                                           :title="__('title.input.model_field', {
                                                                                       field: __('model.figure.attr.vin'),
                                                                                       model: __('model.figure.name')
                                                                                   }) | capitalize"
                                                                           :placeholder="__('model.figure.attr.vin') | capitalize"
                                                                           v-model="figure.vin_to"
                                                                           @click.stop @keydown.space.stop>
                                                                    <div class="input-group-append">
                                                                        <button type="submit" class="btn btn-success btn-sm" @click.stop :disabled="globalVars.isLoading"
                                                                                :title="__('button.save', { model: __('model.figure.name') }) | capitalize">
                                                                            <i class="fas fa-save"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </transition>
                                                </div>
                                                <div class="col">
                                                    <div class="float-right">
                                                        <model-crud-buttons :model-type="'figure'" :model="figure" event="delete-figure" @delete-figure="deleteFigure(figure.id)">
                                                            <template #prepend>
                                                                <button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target="#file-previewer">
                                                                    <i class="fas fa-info"></i>
                                                                </button>
                                                            </template>
                                                        </model-crud-buttons>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="selectedGroupID === 0">
                                    {{ __('text.no_model_selected', { model: __('model.group.name') }) | capitalize }}
                                </div>
                                <div v-else key="no-figures">
                                    {{ __('text.no_models_found', { model: __('model.figure.name', { count: 2 }) }) | capitalize }}
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <h3 class="d-inline-block">{{ __('model.product.name', { count: 2 }) | capitalize }}</h3>

                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                                <button type="button" class="btn btn-primary btn-sm d-inline-block" :title="__('maintenance.title.button.product.link')"
                                        data-toggle="modal" data-target="#link-products-to-figure-modal" v-if="selectedGroupID !== 0"
                                        @click="emitEvent('link-products-modal-opened')">
                                    <i class="fas fa-copy"></i>
                                </button>
                            </transition>

                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                                <button type="button" class="btn btn-primary btn-sm d-inline-block" :title="__('maintenance.title.button.product.edit')"
                                        v-if="productPositionsChanged" :disabled="globalVars.isLoading" @click="saveProducts">
                                    <i class="fas fa-save"></i>
                                </button>
                            </transition>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col scrollable scrollable-sm">
                            <table id="products-table" class="table table-hover bg-white">
                                <thead class="thead-light sticky-top">
                                    <tr>
                                        <th>{{ __('model.product.attr.position') | capitalize }}</th>
                                        <th>{{ __('model.product.attr.number') | capitalize }}</th>
                                        <th>{{ globalVars.lang.toUpperCase() }}</th>
                                        <th>{{ __('model.product.attr.amount') | capitalize }}</th>
                                        <th>{{ __('model.product.attr.data') | capitalize }}</th>
                                        <th class="two-btn-width"></th>
                                    </tr>
                                </thead>
                                <draggable v-model="products" tag="tbody" ghostClass="draggable-ghost" :component-data="getComponentData()">
                                    <template v-if="products != null && products.length > 0">
                                        <tr v-for="( product, index ) in products" :key="product.id" :data-index="index" tabindex="0"
                                            :class="selectedProductID === product.id ? 'bg-primary text-white' : ''"
                                            @mouseover="$set(product, 'hovering', true)" @mouseleave="$set(product, 'hovering', false)"
                                            @focusin="$set(product, 'hovering', true)" @focusout="$set(product, 'hovering', false)"
                                            @click="selectedProductID = product.id" @keydown.enter="selectedProductID = product.id">
                                            <td>{{ product.position }}</td>
                                            <td>
                                                <transition enter-active-class="animated fadeInRight position-absolute"
                                                            leave-active-class="animated fadeOutRight position-absolute">
                                                    <span v-show="!product.edit">{{ product.number }}</span>
                                                </transition>

                                                <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                    <input type="text" class="form-control"
                                                           :title="__('title.input.model_field', {
                                                                       field: __('model.product.attr.number'),
                                                                       model: __('model.product.name')
                                                                   }) | capitalize"
                                                           :placeholder="__('model.product.attr.number') | capitalize"
                                                           v-show="product.edit" v-model="product.number" @keydown.enter="saveProduct(product.id)">
                                                </transition>
                                            </td>
                                            <td>{{ product.name }}</td>
                                            <td>{{ product.amount }}</td>
                                            <td>
                                                <transition enter-active-class="animated fadeInRight position-absolute"
                                                            leave-active-class="animated fadeOutRight position-absolute">
                                                    <span v-show="!product.edit">{{ product.data }}</span>
                                                </transition>

                                                <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                    <div class="input-group" v-show="product.edit">
                                                        <input type="text" class="form-control"
                                                               :title="__('title.input.model_field', {
                                                                           field: __('model.product.attr.data'),
                                                                           model: __('model.product.name')
                                                                       }) | capitalize"
                                                               :placeholder="__('model.product.attr.data') | capitalize"
                                                               v-model="product.data" @keydown.enter="saveProduct(product.id)">
                                                        <div class="input-group-append">
                                                            <button type="button" class="btn btn-success btn-sm"
                                                                    @click="saveProduct(product.id)" :disabled="globalVars.isLoading">
                                                                <i class="fas fa-save"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </td>
                                            <td>
                                                <div class="float-right">
                                                    <model-crud-buttons :model-type="'product'" :model="product" event="unlink-product"
                                                                        @unlink-product="unlinkProductFromFigure(product.id)"></model-crud-buttons>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-else-if="globalVars.isLoading && selectedFigureID" key="loading-products">
                                        <td>
                                            <p>
                                                {{ __('text.loading_models', { model: __('model.product.name', { count: 2 }) }) | capitalize }}
                                                <i class="fas fa-circle-notch fa-spin"></i>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-else-if="selectedFigureID === 0" key="no-figure-selected">
                                        <td>
                                            <p>{{ __('text.no_model_selected', { model: __('model.figure.name') }) | capitalize }}</p>
                                        </td>
                                    </tr>
                                    <tr v-else key="no-products">
                                        <td>
                                            <p>{{ __('text.no_models_found', { model: __('model.product.name', { count: 2 }) }) | capitalize }}</p>
                                        </td>
                                    </tr>
                                </draggable>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 d-flex flex-column">
                    <h3>{{ __('image_preview') }}</h3>
                    <template v-if="selectedFigure">
                        <iframe v-if="selectedFigure.pdf_url" class="w-100 h-100 borderless" :src="selectedFigure.pdf_url"></iframe>
                        <p v-else>{{ __('text.no_models_found', { model: 'PDF' }) | capitalize }}</p>
                    </template>
                    <p v-else>{{ __('text.no_model_selected', { model: __('model.figure.name') }) | capitalize }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '../eventbus';
    import draggable from 'vuedraggable';
    import CreateModelModal from '../components/modals/CreateModelModal';
    import CreateGroupModal from '../components/modals/CreateGroupModal';
    import CopyGroupModal from '../components/modals/CopyGroupModal';
    import CreateFigureModal from '../components/modals/CreateFigureModal';
    import LinkProductsModal from '../components/modals/LinkProductsModal';
    import ErrorMessage from '../components/ErrorMessage';
    import ListStaggered from '../transitions/ListStaggered';
    import ModelCrudButtons from '../components/ModelCrudButtons';
    import LinkGroupsModal from '../components/modals/LinkGroupsModal';
    import ModelGroupInfoModal from '../components/modals/ModelGroupInfoModal';
    import FilePreviewer from '../components/modals/FilePreviewer.vue';

    export default {
        components: {
            FilePreviewer,
            LinkGroupsModal,
            CreateModelModal,
            CreateGroupModal,
            CopyGroupModal,
            CreateFigureModal,
            LinkProductsModal,
            ErrorMessage,
            ListStaggered,
            ModelCrudButtons,
            ModelGroupInfoModal,
            draggable,
        },
        data() {
            return {
                models: null,
                groups: null,
                figures: null,
                products: null,
                error: new _Error(),
                selectedModelID: 0,
                selectedModelNumber: null,
                selectedModelGroup: new ModelGroup(),
                selectedGroupID: 0,
                selectedGroupNumber: null,
                selectedFigureID: 0,
                selectedProductID: 0,
                productPositionsChanged: false,
                animationDuration: 150,

                PRE_POST_TYPE: PRE_POST_TYPE,
            };
        },
        computed: {
            clonedFigures: function () {
                return _.cloneDeep(this.figures);
            },
            selectedFigure() {
                if (this.selectedFigureID) {
                    return this.getItemFromArrayByID(this.figures, this.selectedFigureID);
                } else {
                    return null;
                }
            },
        },
        created() {
            this.getModels();

            EventBus.$on('get-models', args => {
                this.getModels().then(() => {
                    if (!_.isEmpty(args)) {
                        if (!_.isNil(args['selectedModelID'])) {
                            this.selectedModelID = args['selectedModelID'];
                            this.getGroups(this.selectedModelID);
                        }
                        if (!_.isNil(args['selectedGroupID'])) {
                            this.selectedGroupID = args['selectedGroupID'];
                            this.getFigures(this.selectedGroupID);
                        }
                    }
                });
            });

            EventBus.$on('get-figures', (groupID) => {
                if (!_.isNil(groupID) && _.isNumber(groupID)) {
                    this.getFigures(groupID);
                }
            });

            EventBus.$on('get-figure-products', (figureID) => {
                if (!_.isNil(figureID) && _.isNumber(figureID)) {
                    this.getFigureProducts(figureID);
                }
            });
        },
        destroyed() {
            EventBus.$off('get-models');
            EventBus.$off('get-figures');
            EventBus.$off('get-figure-products');
        },
        methods: {
            async getModels() {
                this.models = this.groups = this.figures = this.products = null;
                this.selectedModelID = this.selectedGroupID = this.selectedFigureID = this.selectedProductID = 0;
                this.productPositionsChanged = false;
                this.emitEvent('model-selected', 0);

                await axios
                    .get('/api/models', {
                        params: {
                            with_groups: true,
                            with_figures: true,
                            with_group_image: true,
                        },
                    })
                    .then(response => {
                        if (!_.isEmpty(response)) {
                            if (response.status === HTTP_OK) {
                                this.models = response.data.result;
                            }
                        }
                    })
                    .catch(error => {
                        this.error = this.createErrorFromRequest(error);
                    })
                ;
            },
            getGroups(modelID) {
                this.groups = this.figures = this.products = null;
                this.selectedGroupID = this.selectedFigureID = this.selectedProductID = 0;
                this.productPositionsChanged = false;

                if (!_.isNil(modelID) && !_.isNil(this.models)) {
                    let foundModel = this.getItemFromArrayByID(this.models, modelID);
                    if (!_.isNil(foundModel)) {
                        this.selectedModelNumber = foundModel.number;
                        this.groups = foundModel.groups;
                    }

                    this.selectedModelID = modelID;
                    this.emitEvent('model-selected', modelID);
                }
            },
            getFigures(groupID) {
                this.figures = this.products = null;
                this.selectedFigureID = this.selectedProductID = 0;
                this.productPositionsChanged = false;
                this.emitEvent('figure-selected', 0);

                if (!_.isNil(groupID) && !_.isNil(this.groups)) {
                    let foundGroup = this.getItemFromArrayByID(this.groups, groupID);
                    if (!_.isNil(foundGroup)) {
                        this.selectedGroupNumber = foundGroup.number;
                        this.figures = foundGroup.figures;
                    }

                    this.selectedGroupID = groupID;
                    this.selectedGroupFigures = this.getSelectedGroupFigures(groupID);
                    this.emitEvent('group-selected', groupID);
                }
            },
            getFigureProducts(figureID) {
                this.products = null;
                this.selectedProductID = 0;
                this.productPositionsChanged = false;

                if (!_.isNil(figureID)) {
                    this.selectedFigureID = figureID;
                    this.emitEvent('figure-selected', figureID);

                    axios
                        .get('/api/figures/' + figureID, {
                            params: {
                                with_products: '',
                            },
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    this.products = response.data.result.products;
                                    for (let product of this.products) {
                                        product.oldNumber = product.number;
                                    }
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            saveModel(modelID) {
                this.error = new _Error();

                if (!_.isNil(modelID) && _.isInteger(modelID)) {
                    let foundModel = this.getItemFromArrayByID(this.models, modelID);
                    if (!_.isNil(foundModel)) {
                        axios
                            .put('/api/models/' + modelID, {
                                model: foundModel,
                            })
                            .then(response => {
                                if (!_.isEmpty(response)) {
                                    this.error = response.data.error;

                                    if (response.status === HTTP_OK) {
                                        foundModel.edit = false;
                                    }
                                }
                            })
                            .catch(error => {
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    }
                }
            },
            saveGroup(groupID) {
                this.error = new _Error();

                if (groupID && _.isNumber(groupID)) {
                    let foundGroup = this.getItemFromArrayByID(this.groups, groupID);
                    if (!_.isNil(foundGroup)) {
                        axios
                            .put('/api/groups/' + groupID, {
                                group: foundGroup,
                            })
                            .then(response => {
                                if (!_.isEmpty(response)) {
                                    this.error = response.data.error;

                                    if (response.status === HTTP_OK) {
                                        this.emitEvent('get-models', {
                                            selectedModelID: this.selectedModelID,
                                            selectedGroupID: groupID,
                                        });
                                    }
                                }
                            })
                            .catch(error => {
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    }
                }
            },
            saveFigure(figureID) {
                this.error = new _Error();

                if (figureID && _.isNumber(figureID)) {
                    let foundFigure = this.getItemFromArrayByID(this.figures, figureID);
                    if (!_.isNil(foundFigure)) {
                        axios
                            .put('/api/figures/' + figureID, {
                                figure: foundFigure,
                            })
                            .then(response => {
                                if (!_.isEmpty(response)) {
                                    this.error = response.data.error;

                                    if (response.status === HTTP_OK) {
                                        foundFigure.edit = false;
                                    }
                                }
                            })
                            .catch(error => {
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    }
                }
            },
            saveProduct(productID) {
                this.error = new _Error();

                if (productID && _.isNumber(productID)) {
                    let product = this.getItemFromArrayByID(this.products, productID);
                    if (!_.isNil(product)) {
                        let productNumberChanged = product.number !== product.oldNumber;

                        let url = 'api';
                        url += productNumberChanged ? '/figures/' + this.selectedFigureID : ''; // Check if product.number has changed
                        url += '/products/' + productID;

                        axios
                            .put(url, {
                                product: product,
                            })
                            .then(response => {
                                if (!_.isEmpty(response)) {
                                    this.error = response.data.error;

                                    switch (response.status) {
                                        case HTTP_OK:
                                            product.edit = false;
                                            break;
                                        case HTTP_CREATED:
                                            this.getFigureProducts(this.selectedFigureID);
                                            break;
                                    }
                                }
                            })
                            .catch(error => {
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    }
                }
            },
            saveProducts() {
                if (!_.isEmpty(this.products) && this.selectedFigureID) {
                    axios
                        .patch('/api/figures/' + this.selectedFigureID + '/products', {
                            products: this.products,
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    this.productPositionsChanged = false;
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            unlinkGroupFromModel(modelID, groupID) {
                if (modelID && _.isNumber(modelID) && groupID && _.isNumber(groupID)) {
                    axios
                        .delete('/api/models/' + modelID + '/groups/' + groupID)
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    let model = this.getItemFromArrayByID(this.models, modelID);
                                    if (!_.isNil(model)) {
                                        _.remove(model.groups, (group) => group.id === groupID);
                                        this.getGroups(modelID);
                                    }

                                    this.emitEvent('invalidate-groups');
                                    this.emitEvent('model-selected', modelID);
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            deleteFigure(figureID) {
                if (figureID && _.isNumber(figureID)) {
                    axios
                        .delete('/api/figures/' + figureID)
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    this.emitEvent('get-models', {
                                        selectedModelID: this.selectedModelID,
                                        selectedGroupID: this.selectedGroupID,
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            unlinkProductFromFigure(productID) {
                if (productID && _.isNumber(productID) && this.selectedFigureID) {
                    axios
                        .delete('/api/figures/' + this.selectedFigureID + '/products/' + productID)
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    this.products = this.products.filter(product => product.id !== productID);
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            getComponentData() {
                return {
                    on: {
                        end: () => {
                            this.updateProductsPosition();
                        },
                    },
                };
            },
            updateProductsPosition() {
                this.products.forEach((product, index) => {
                    let oldPosition = product.position.toString();
                    product.position = ++index + '0';

                    if (product.position !== oldPosition) {
                        this.productPositionsChanged = true;
                    }
                });
            },
            getSelectedGroupFigures(groupID) {
                if (groupID && _.isNumber(groupID)) {
                    let group = this.getItemFromArrayByID(this.groups, groupID);
                    if (!_.isNil(group) && !_.isNil(group.figures)) {
                        return group.figures;
                    }
                }

                return [];
            },
        },
    };
</script>
