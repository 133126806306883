<template>
    <div class="container-fluid">
        <div class="row">
            <div id="footer" class="col">
                <div class="text-center">
                    <p>© Copyright 2020 Online Supporter</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {}
</script>
