<template>
    <button type="button" class="btn btn-danger" @click="clicked"><i v-if="icon != null && icon !== ''" :class="'fas fa-' + icon"></i>{{ text }}</button>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        data() {
            return {

            }
        },
        props: {
            icon: {
                type: String,
                default: 'trash',
            },
            text: {
                type: String,
                default: '',
            },
            data: null,
        },
        methods: {
            clicked() {
                Swal.fire({
                    title: this.__('confirm_dialog.title'),
                    text: this.__('confirm_dialog.text'),
                    icon: 'warning',
                    showConfirmButton: true,
                    confirmButtonText: this.__('confirm_dialog.button.confirm'),
                    showCancelButton: true,
                    cancelButtonText: this.__('confirm_dialog.button.cancel'),
                }).then(result => {
                    if (result.isConfirmed) {
                        this.$emit('confirmed', this.data);
                    } else {
                        this.$emit('dismissed');
                    }
                });
            },
        },
    }
</script>
