<template>
    <div class="row" v-if="pagination">
        <div class="col mt-2 mb-2">
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li class="page-item">
                        <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                            <a href="#" class="page-link" :title="__('pagination.first_page')"
                               v-show="pagination.total > pagination.per_page" @click.prevent="switchPage(pagination.first_page_url)">
                                <i class="fas fa-chevron-left"></i><i class="fas fa-chevron-left"></i>
                            </a>
                        </transition>
                    </li>
                    <li class="page-item" :class="{ disabled: !pagination.prev_page_url }">
                        <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                            <a href="#" class="page-link" :title="__('pagination.previous_page')"
                               v-show="pagination.total > pagination.per_page" @click.preven="switchPage(pagination.prev_page_url)">
                                <i class="fas fa-chevron-left"></i>
                            </a>
                        </transition>
                    </li>

                    <li class="page-item" :class="{ active: pageNumber === pagination.current_page, }" v-for="pageNumber of pageNumbers">
                        <a href="#" class="page-link" :title="__('pagination.page_at', { page: pageNumber })"
                           @click.prevent="switchPage(getPageUrlByNumber(pageNumber))">
                            {{ pageNumber }}
                        </a>
                    </li>

                    <li class="page-item" :class="{ disabled: !pagination.next_page_url }">
                        <transition enter-active-class="animated fadeInRight" leave-active-class="animated fadeOutRight">
                            <a href="#" class="page-link" :title="__('pagination.next_page')"
                               v-show="pagination.total > pagination.per_page" @click.prevent="switchPage(pagination.next_page_url)">
                                <i class="fas fa-chevron-right"></i>
                            </a>
                        </transition>
                    </li>
                    <li class="page-item">
                        <transition enter-active-class="animated fadeInRight" leave-active-class="animated fadeOutRight">
                            <a href="#" class="page-link" :title="__('pagination.last_page')"
                               v-show="pagination.total > pagination.per_page" @click.prevent="switchPage(pagination.last_page_url)">
                                <i class="fas fa-chevron-right"></i><i class="fas fa-chevron-right"></i>
                            </a>
                        </transition>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageNumbers: [],
                pageNumbersMargin: 4,
            }
        },
        props: {
            pagination: new Pagination(),
        },
        watch: {
            'pagination.next_page_url': function () {
                this.pageNumbers = [];

                if (this.pagination.total > this.pagination.per_page) {
                    let min = this.pagination.current_page >= this.pagination.last_page - this.pageNumbersMargin
                        ? this.pagination.last_page - (this.pageNumbersMargin * 2)
                        : this.pagination.current_page - this.pageNumbersMargin;

                    let max = this.pagination.current_page <= this.pageNumbersMargin
                        ? 1 + (this.pageNumbersMargin * 2)
                        : this.pagination.current_page + this.pageNumbersMargin;

                    let i = _.max([min, 1]);

                    while (i <= _.min([max, this.pagination.last_page])) {
                        this.pageNumbers.push(i++);
                    }
                }
            }
        },
        methods: {
            switchPage(url) {
                this.$emit('switch', {'url': url});
            },
            getPageUrlByNumber(page) {
                let url = this.pagination.first_page_url;
                url = url.slice(0, url.length - 1);
                url += page;

                return url;
            },
        },
    }
</script>