<template>
    <div v-if="model != null" class="d-inline-block">
        <!-- CRUD buttons -->
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div v-show="(model.hovering || model.edit) && !model.confirmation">
                <slot name="prepend"></slot>
                <button v-if="hasPermission(modelType + '_edit') && edit" type="button" class="btn btn-light btn-sm ml-1 mr-1" :title="__('maintenance.title.button.crud.edit')"
                        @click.stop="$set(model, 'edit', !model.edit)">
                    <i class="fas fa-edit"></i>
                </button>
                <button v-if="hasPermission(modelType + '_delete') && remove" type="button" class="btn btn-danger btn-sm ml-1 mr-1" :title="__('maintenance.title.button.crud.delete')"
                        @click.stop="$set(model, 'confirmation', true)">
                    <i class="fas fa-trash"></i>
                </button>
                <slot name="append"></slot>
            </div>
        </transition>

        <!-- Confirmation buttons -->
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div v-show="model.confirmation">
                <button type="button" class="btn btn-success btn-sm ml-1 mr-1"
                        :title="__('maintenance.title.button.crud.delete') + ' ' + __('maintenance.title.button.crud.cannot_undo')"
                        @click.stop="$emit(event, model.id)" :disabled="globalVars.isLoading">
                    <i class="fas fa-check"></i>
                </button>
                <button type="button" class="btn btn-danger btn-sm ml-1 mr-1" :title="__('maintenance.title.button.crud.cancel')"
                        @click.stop="$set(model, 'confirmation', false)">
                    <i class="fas fa-times fa-lg"></i>
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            modelType: String,
            model: {
                type: Object,
                default: function () {
                    return {
                        edit: false,
                        hovering: false,
                        confirmation: false,
                    }
                }
            },
            event: String,
            edit: {
                type: Boolean,
                default: true,
            },
            remove: {
                type: Boolean,
                default: true,
            }
        },
    }
</script>
