<template>
    <div class="modal fade" id="product-info-modal" tabindex="-1" role="dialog" aria-labelledby="product-info-modal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('model.product.name') | capitalize }}: {{ theProduct.name }} ({{ theProduct.number }})</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="product-form" @submit.prevent="saveProduct('product-form', theProduct.id)">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                <h3>{{ __('model.figure.name_plural') | capitalize }}</h3>

                                <template v-if="theProduct.figures">
                                    <div class="list-group scrollable scrollable-md">
                                        <div v-for="figure of theProduct.figures" class="list-group-item">
                                            {{ figure.code }}
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    {{ __('text.no_models_found', { model: __('model.figure.name_plural') }) | capitalize }}
                                </template>
                            </div>

                            <div class="col">
                                <fieldset class="mb-3">
                                    <legend>{{ __('model.product.name') | capitalize }} {{ __('model.product.attr.info_file.name') }}</legend>

                                    <div class="form-group">
                                        <input type="file" class="form-control" name="info_file">
                                    </div>

                                    <div v-if="theProduct.info_file != null" class="list-group">
                                        <div class="list-group-item">
                                            <div class="row">
                                                <div class="col"><span class="align-middle">{{ theProduct.info_file.basename }}</span></div>
                                                <div class="col">
                                                    <confirm-buttons :data="{productId: theProduct.id}" @confirmed="deleteProductFile($event, 'info_file')"></confirm-buttons>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset>
                                    <legend>{{ __('model.product.name') | capitalize }} {{ __('model.product.attr.thumbnail.name') }}</legend>

                                    <div class="form-group">
                                        <input type="file" class="form-control" name="thumbnail">
                                    </div>

                                    <div v-if="theProduct.thumbnail != null" class="list-group">
                                        <div class="list-group-item">
                                            <div class="row hover-pointer" data-toggle="collapse" :data-target="`#thumbnail-${theProduct.id}`">
                                                <div class="col"><span class="align-middle">{{ theProduct.thumbnail.basename }}</span></div>
                                                <div class="col">
                                                    <confirm-buttons :data="{productId: theProduct.id}" @confirmed="deleteProductFile($event, 'thumbnail')"></confirm-buttons>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div :id="`thumbnail-${theProduct.id}`" class="collapse">
                                                        <div class="card mt-3">
                                                            <div class="card-body">
                                                                <img :src="theProduct.thumbnail.path" alt="" class="w-100">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <error-message :error="error" :timeout="true" classes="mt-3"></error-message>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">
                            {{ __('button.save', {model: __('model.product.name')}) | capitalize }} <i v-show="globalVars.isLoading" class="fas fa-circle-notch fa-spin"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from "../ErrorMessage";
    import ConfirmButtons from "../util/ConfirmButtons";

    export default {
        components: {
            ErrorMessage,
            ConfirmButtons,
        },
        data() {
            return {
                error: new _Error(),
                theProduct: new Product(),
            }
        },
        props: {
            product: Object,
        },
        watch: {
            product: function () {
                this.theProduct = this.product;
            },
        },
        methods: {
            fetchProduct(productID) {
                axios
                    .get(`/api/products/${productID}`, {
                        params: {
                            with_figures: true,
                        }
                    })
                    .then(response => {
                        this.theProduct = response.data.result;
                    })
                    .catch();
            },
            saveProduct(formID, productID) {
                if (productID > 0) {
                    let formData = new FormData(document.getElementById(formID));

                    axios({
                        method: 'post',
                        url: `/api/products/${productID}`,
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                        .then(response => {
                            this.error = response.data.error;

                            this.resetForm('product-form');

                            this.fetchProduct(productID);
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        });
                }
            },
            deleteProductFile(data, type) {
                if (!_.isNil(data) && !_.isNil(data.productId)) {
                    axios
                        .delete(`/api/products/${data.productId}/${type}`)
                        .then(response => {
                            this.error = response.data.error;

                            if (response.status === HTTP_OK) {
                                this.fetchProduct(data.productId);
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        });
                }
            },
        },
    }
</script>
