<template>
    <div class="row">
        <div class="col">
            <error-message :error="error" :toast="true" positioning="floating" :timeout="true" classes="mt-3"
                           enter-animation="slideInLeft" leave-animation="slideOutLeft"></error-message>

            <div class="row">
                <div class="col">
                    <h2 class="d-inline-block">{{ __('account.manage.companies.header') }}</h2>
                    <button type="button"
                            class="btn btn-primary btn-sm"
                            :title="__('button.reload', { model: __('model.company.name', { count: 2 }) }) | capitalize"
                            @click="getCompanies"
                    >
                        <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading }"></i>
                    </button>

                    <div class="mb-3" style="display: flex; align-items: center; justify-content: space-between;">
                        <p class="m-0">{{ __('account.manage.companies.page_info') }}</p>

                        <button type="button" class="btn btn-success" @click="addCompany"><i class="fas fa-plus"></i></button>
                    </div>
                </div>
            </div>

            <template v-if="companies.length === 0 && globalVars.isLoading">
                <div>{{ __('text.loading_models', { model: __('model.company.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i></div>
            </template>
            <template v-else-if="companies.length > 0">
                <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); gap: 1rem;">
                    <div v-for="company in companies" class="card">
                        <img class="card-img-top p-4 bg-primary text-white" :src="company.logo != null ? company.logo.url : ''" alt="Company thumbnail."
                             style="height: 150px; object-fit: contain;">
                        <div class="card-body">
                            <form :id="`company-${company.id}-form`" @submit.prevent="saveCompany(company.id)">
                                <h5 class="card-title mt-0">{{ company.name }}</h5>

                                <div class="mb-4" style="display: grid; gap: 1rem;">
                                    <div v-if="company.id < 0" class="alert alert-warning m-0">{{ __('account.manage.companies.creation_warning') }}</div>

                                    <div>
                                        <label class="mb-1" :for="`company-${company.id}-name`">{{ __('model.company.attr.name') | capitalize }}</label>
                                        <input type="text" :id="`company-${company.id}-name`" class="form-control" name="name" v-model="company.name" required>
                                    </div>

                                    <div>
                                        <label class="mb-1" :for="`company-${company.id}-thumbnail`">{{ __('model.company.attr.logo') | capitalize }}</label>
                                        <input type="file" :id="`company-${company.id}-thumbnail`" class="form-control" name="logo" accept="image/png">
                                    </div>
                                </div>

                                <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">
                                    <i class="fas fa-save mr-2"></i>{{ __('button.save', { model: company.name }) }}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>{{ __('text.no_models_found', { model: __('model.company.name', { count: 2 }) }) }}</div>
            </template>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from '../../components/ErrorMessage.vue';
    import ConfirmDialog from '../../components/util/ConfirmDialog.vue';
    import ModelCrudButtons from '../../components/ModelCrudButtons.vue';

    export default {
        components: {
            ModelCrudButtons,
            ConfirmDialog,
            ErrorMessage,
        },
        data() {
            return {
                error: new _Error(),
                decrementingId: 0,
                companies: [],
            };
        },
        created() {
            this.getCompanies();
        },
        methods: {
            getCompanies() {
                this.companies = [];

                axios
                    .get('/api/companies', {
                        params: {
                            with_users: true,
                        },
                    })
                    .then(response => {
                        if (!_.isEmpty(response) && response.status === HTTP_OK && !_.isNil(response.data.result)) {
                            this.companies = response.data.result;

                            for (let company of this.companies) {
                                this.$set(company, 'hovering', false);
                                this.$set(company, 'edit', false);
                            }
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
                ;
            },
            addCompany() {
                this.companies.push({
                    id: --this.decrementingId,
                    name: 'New Company',
                    hovering: false,
                    edit: true,
                });
            },
            saveCompany(companyID) {
                const formData = new FormData(document.getElementById(`company-${companyID}-form`));

                const url = '/api/companies' + (companyID > 0 ? `/${companyID}` : '');

                axios
                    .post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(response => {
                        this.getCompanies();

                        this.error = response.data.error;
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
                ;
            },
        },
    };
</script>
