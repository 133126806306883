<template>
    <div class="modal fade" id="link-products-to-figure-modal" tabindex="-1" role="dialog" aria-labelledby="link-products-to-figure-modal"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('link_products.header') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="link-products-form" action="" method="post" @submit.prevent="linkProducts">
                    <div class="modal-body">
                        <error-message :error="error"></error-message>

                        <div class="form-group">
                            <label for="select-figure">{{ __('model.figure.name') | capitalize }}</label>
                            <select id="select-figure" class="form-control" v-model="selectedFigureID" required>
                                <option value="0" disabled>{{ __('text.select_item') }}</option>
                                <option v-for="{ id, number, code } in parentFigures" :value="id">{{ number }}: {{ code }}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>{{ __('model.product.name', { count: 2 }) | capitalize }}</label>

                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                                <div v-if="dropdownOptions != null && dropdownOptions.length > 0" key="found-products">
                                    <dropdown :options="dropdownOptions" placeholder="-- selecteer een product --" :max-item="10"
                                              @selected="selectedProduct.id = $event.id">
                                    </dropdown>
                                </div>
                                <div v-else-if="dropdownOptions != null && globalVars.isLoading" key="loading-products">
                                    {{ __('text.loading_models', { model: __('model.product.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                                </div>
                                <div v-else key="no-products">
                                    {{ __('text.no_models_found', { model: __('model.product.name', { count: 2 }) }) | capitalize }}
                                </div>
                            </transition>
                        </div>

                        <div class="form-group">
                            <label for="input-amount">{{ __('model.product.attr.amount') | capitalize }}</label>
                            <input type="number" id="input-amount" class="form-control" min="1" v-model="selectedProduct.amount" required>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('button.close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('button.save', { model: __('model.product.name') }) | capitalize }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../../eventbus";
    import ErrorMessage from "../ErrorMessage";
    import Dropdown from 'vue-simple-search-dropdown';

    export default {
        components: {
            ErrorMessage,
            Dropdown,
        },
        data() {
            return {
                error: new _Error(),
                dropdownOptions: [],
                selectedFigureID: 0,
                selectedProduct: new Product(),
            }
        },
        props: {
            parentFigures: Array,
        },
        created() {
            let self = this;

            EventBus.$on('link-products-modal-opened', () => {
                if (_.isEmpty(this.dropdownOptions)) {
                    this.getAllProducts();
                }
            });

            EventBus.$on('figure-selected', (figureID) => {
                if (!_.isNil(figureID) && _.isNumber(figureID)) {
                    this.selectedFigureID = figureID;
                }
            });

            $(function () {
                $('#link-products-to-figure-modal').on('hidden.bs.modal', function () {
                    self.error = new _Error();
                    self.resetForm('link-products-form');
                });
            });
        },
        destroyed() {
            EventBus.$off('link-products-modal-opened');
            EventBus.$off('figure-selected');
        },
        methods: {
            getAllProducts() {
                // @TODO: Use query to filter data, since there are too many products

                // axios
                //     .get('/api/products')
                //     .then(response => {
                //         if (!_.isEmpty(response)) {
                //             if (response.status === HTTP_OK) {
                //                 for (let product of response.data.result) {
                //                     this.dropdownOptions.push({ id: product.id, name: product.number + ': ' + product.name });
                //                 }
                //             }
                //         }
                //     })
                //     .catch(error => {
                //         this.error = this.createErrorFromRequest(error);
                //     })
                // ;
            },
            linkProducts() {
                this.error = new _Error();

                if (!_.isNil(this.selectedFigureID) && _.isNumber(this.selectedFigureID)) {
                    if (!this.hasEmptyAttributes(this.selectedProduct, ['id', 'amount'])) {
                        axios
                            .post('/api/figures/' + this.selectedFigureID + '/products', {
                                products: [this.selectedProduct],
                            })
                            .then(response => {
                                if (!_.isEmpty(response)) {
                                    this.error = response.data.error;

                                    if (response.status === HTTP_CREATED) {
                                        this.selectedProduct.id = 0;
                                        this.selectedProduct.amount = '';
                                        this.resetForm('link-products-form');
                                        this.emitEvent('get-figure-products', this.selectedFigureID);
                                    }
                                }
                            })
                            .catch(error => {
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    } else {
                        this.error = new _Error(Status.WARNING, 0, 'Geen product geselecteerd.');
                    }
                }
            },
        },
    }
</script>
