import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from 'nprogress/nprogress';
import {EventBus} from "./eventbus";
import i18next from "i18next";
import moment from "vue-moment";
import Viewer from "v-viewer";
import App from "./views/App";
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import Home from "./views/Home";
import Maintenance from "./views/Maintenance";
import Translations from "./views/Translations";
import Exports from "./views/Exports";
import Imports from "./views/Imports";
import AdminMaintenance from "./views/AdminMaintenance";
import PageNotFound from "./views/PageNotFound";
import ManageAccount from "./views/account/ManageAccount";
import Account from "./views/account/Account";
import ManageUsers from "./views/account/ManageUsers";
import CreateEditUser from "./views/account/CreateEditUser";
import FigureHotspots from "./views/FigureHotspots";
import ManageCompanies from './views/account/ManageCompanies.vue';

require('./bootstrap');

Vue.use(moment);
Vue.use(Viewer);

// Setup localization
import en_frontend from './lang/en/front-end';
import nl_frontend from './lang/nl/front-end';
i18next.init({
    lng: DEFAULT_LANG,
    resources: {
        nl: {
            translation: nl_frontend,
        },
        en: {
            translation: en_frontend,
        },
        fr: {
            translation: en_frontend,
        },
    }
});

// Setup routing
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/', name: 'home', component: Home, alias: '/home',
        },
        {
            path: '/maintenance', name: 'maintenance', component: Maintenance,
            meta: {
                is_menu_item: true, requireAuth: true,
            },
        },
        {
            path: '/translations', name: 'translations', component: Translations,
            meta: {
                is_menu_item: true, requireAuth: true,
            },
        },
        {
            path: '/exports', name: 'exports', component: Exports,
            meta: {
                is_menu_item: true, requireAuth: true,
            },
        },
        {
            path: '/imports', name: 'imports', component: Imports,
            meta: {
                is_menu_item: true, requireAuth: true,
            },
        },
        {
            path: '/hotspots', name: 'hotspots', component: FigureHotspots,
            meta: {
                is_menu_item: true, requireAuth: false, // @FIXME: requireAuth should be true, but has been disabled for testing purposes.
            },
        },
        {
            path: '/admin-maintenance', name: 'admin_maintenance', component: AdminMaintenance,
            meta: {
                is_menu_item: true, requireAuth: true,
            },
        },
        {
            path: '/account', name: 'account', component: Account,
            meta: {
                requireAuth: true,
            },
            children: [
                {
                    path: 'manage', name: 'manage_account', component: ManageAccount,
                    meta: {
                        requireAuth: true,
                    },
                },
                {
                    path: 'users', name: 'manage_users', component: ManageUsers,
                    meta: {
                        requireAuth: true,
                    },
                },
                {
                    path: 'user/:id?', name: 'create_edit_user', component: CreateEditUser, props: true,
                    meta: {
                        requireAuth: true,
                    },
                },
                {
                    path: 'companies', name: 'manage_companies', component: ManageCompanies,
                    meta: {
                        requireAuth: true,
                    },
                },
            ],
        },
        {
            path: '*', name: 'page-not-found', component: PageNotFound,
        },
    ],
});

// Globally used variables
const globalVars = Vue.observable({
    routes: router.options.routes,
    isLoading: false,
    globalError: new _Error(),
    csrf_token: '',
    user: null,
    permissions: [],
    lang: DEFAULT_LANG,
});

// Get permissions for the current logged in user
async function getPermissions() {
    if (globalVars.permissions.length === 0) {
        await axios
            .get('/api/users/current/permissions')
            .then(response => {
                if (!_.isEmpty(response) && !_.isNil(response.data) && !_.isNil(response.data.result) && response.status === HTTP_OK) {
                    globalVars.permissions = response.data.result;
                }
            })
        ;
    }
}

// Routing hooks
router.beforeEach((to, from, next) => {
    // Check if the current logged in user needs/has permission to enter the route
    getPermissions().then(() => {
        if (!_.isNil(to.meta) && !!to.meta.requireAuth) {
            if (globalVars.permissions.length > 0) {
                if (globalVars.permissions.includes(to.name)) {
                    // User has permission to enter the route
                    next();
                } else {
                    // Fallback to the homepage
                    next({ name: 'home' });
                }
            } else {
                // Fallback to the homepage
                next({ name: 'home' });
            }
        } else {
            // User doesn't need permission to enter the route
            next();
        }
    });
});
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        // @TODO: Should be translated too
        document.title = 'RMA - ' + i18next.t('nav.' + to.name);
    });
});

// AJAX loading-bar
axios.interceptors.request.use(config => {
    NProgress.start();
    globalVars.isLoading = true;

    return config;
});
axios.interceptors.response.use(response => {
    NProgress.done();
    globalVars.isLoading = false;

    return response;
}, error => {
    NProgress.done();
    globalVars.isLoading = false;

    return Promise.reject(error);
});

// Setup
$(function () {
    Vue.mixin({
        data() {
            return {
                get globalVars() {
                    return globalVars;
                },
            }
        },
        methods: {
            emitEvent(eventName, data) {
                EventBus.$emit(eventName, data);
            },
            resetForm(id = '') {
                if (id) {
                    $('#' + id).trigger('reset');
                } else {
                    $('form').trigger('reset');
                }
            },
            exportModel(format, model, modelID = 0, params = {}) {
                if (format && _.isString(format) && model && _.isString(model)) {
                    globalVars.globalError = new _Error();

                    let url = '/api/exports/' + format + '/' + model;
                    if (modelID && _.isNumber(modelID)) {
                        url += '/' + modelID;
                    }

                    axios
                        .put(url, params)
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                globalVars.globalError = response.data.error;

                                if (response.status === HTTP_CREATED) {
                                    if (_.isObject(response.data.result)) {
                                        window.location.href = `/exports/${response.data.result.path}`;

                                        this.emitEvent('model-exported', {
                                            modelID: modelID,
                                            params: params,
                                            data: response.data.result,
                                        });
                                    } else {
                                        globalVars.globalError = new _Error(Status.WARNING, 0, 'Received data is incorrectly formatted.');
                                    }
                                }
                            }
                        })
                        .catch(error => {
                            globalVars.globalError = this.createErrorFromRequest(error);
                        })
                    ;
                } else {
                    globalVars.globalError = new _Error(Status.WARNING, 0, 'Not enough data was given.');
                }
            },
            createErrorFromRequest(requestError) {
                if (!_.isEmpty(requestError)) {
                    return !_.isEmpty(requestError.response)
                        ? requestError.response.data.error || new _Error(Status.FAILURE, 0, requestError.response.data.message)
                        : new _Error(Status.FAILURE, 0, requestError.message);
                } else {
                    return new _Error();
                }
            },
            getItemFromArrayByID(array, id) {
                let foundItem = null;

                if (array && id) {
                    $.each(array, function (index, item) {
                        if (item.id === id) {
                            foundItem = item;
                            return false;
                        }
                    });
                }

                return foundItem;
            },
            hasEmptyAttributes(object, attributes) {
                if (_.isObject(object)) {
                    let attributesToCheck;

                    if (_.isArray(attributes) && _.size(attributes) > 0) {
                        attributesToCheck = attributes;
                    } else {
                        attributesToCheck = Object.keys(object);
                    }

                    for (let attribute of attributesToCheck) {
                        if (object.hasOwnProperty(attribute) && _.isNil(object[attribute])) {
                            return true;
                        } else if ((_.isObject(object[attribute]) || _.isArrayLike(object[attribute])) && _.isEmpty(object[attribute])) {
                            return true;
                        }
                    }
                }

                return false;
            },
            __(key, options = null) {
                return i18next.t(key, options);
            },
            changeLanguage(lang) {
                if (LANGUAGES.includes(lang)) {
                    i18next.changeLanguage(lang);
                }
            },
            hasPermission(permission) {
                return globalVars.permissions.includes(permission);
            },
            randomString() {
                return Math.random().toString(36).substr(2, 8);
            },
            capitalize(value) {
                return capitalize(value);
            },
            asArray(variable) {
                if (!_.isArray(variable)) {
                    variable = [variable];
                }

                return variable;
            },
            updateObjectProperties(object1, object2) {
                for (let prop in object2) {
                    object1[prop] = object2[prop];
                }
            },
        }
    });

    Vue.filter('capitalize', function (value) {
        return capitalize(value);
    });
    Vue.filter('snakeToTitle', function (str) {
        return str.split('_').map(function (item) {
            return capitalize(item);
        }).join(' ');
    });

    const app = new Vue({
        el: '#app',
        components: {
            App,
            HeaderComponent,
            FooterComponent,
        },
        router,
    });
});

// Helper functions
function capitalize(value) {
    if (value) {
        value = value.toString().trim();
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return '';
}
