<template>
    <div class="row">
        <div class="col">
            <edit-hotspots-modal :ocr-process="selectedOcrProcess" @hotspots-saved="importOcrProcessFilesById" @switch-ocr-process="switchOcrProcess"></edit-hotspots-modal>

            <error-message :error="error" :timeout="true" :toast="true" classes="mt-3" enter-animation="fadeInLeft" leave-animation="fadeOutLeft"
                           positioning="floating"></error-message>

            <div class="row">
                <div class="col-sm-6">
                    <h3>{{ __('model.hotspot.name_plural') | capitalize }}</h3>

                    <div class="row">
                        <div class="col">
                            <template v-if="globalVars.isLoading && models.length === 0">
                                {{ __('text.loading_models', { model: __('model.model.name_plural') }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                            </template>
                            <template v-else-if="models.length > 0">
                                <div class="form-group">
                                    <label for="select-model">{{ __('model.model.name') | capitalize }}</label>
                                    <select id="select-model" v-model="selectedModelID" class="form-control w-auto" @change="fetchModel(selectedModelID)">
                                        <option :value="null">{{ __('text.select_item') }}</option>
                                        <option v-for="model of models" :value="model.id">{{ model.number }}: {{ model.name }}</option>
                                    </select>
                                </div>
                            </template>
                            <template v-else>
                                {{ __('text.no_models_found', { model: __('model.model.name_plural') }) | capitalize }}
                            </template>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div v-show="selectedModelID != null" id="groups-accordion">
                                <template v-if="globalVars.isLoading && model.groups.length === 0">
                                    {{ __('text.loading_models', { model: __('model.group.name_plural') }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                                </template>
                                <template v-else-if="model.groups.length > 0">
                                    <div v-for="group of model.groups" class="card"
                                         @mouseleave="group.hover = false" @mouseover="group.hover = true">
                                        <div :data-target="`#group-${group.id}-figures`"
                                             class="card-header hover hover-pointer mh-btn-sm d-flex justify-content-between align-items-center"
                                             data-toggle="collapse"
                                             @click="setSelectedGroupID(group.id)">
                                            {{ group.number }}: {{ group.name }}

                                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                                                <div v-show="group.hover">
                                                    <button :title="__('figure_hotspots.enqueue_ocr_process_button.title', {model: __('model.group.name')})"
                                                            class="btn btn-secondary btn-sm"
                                                            type="button"
                                                            @click.stop="enqueueOcrProcessesOfGroups(group)">
                                                        <i class="fas fa-redo-alt"></i>
                                                    </button>
                                                </div>
                                            </transition>
                                        </div>

                                        <div :id="`group-${group.id}-figures`" :class="{show: group.id === selectedGroupID}" class="collapse" data-parent="#groups-accordion">
                                            <div class="card-body">
                                                <div :id="`group-${group.id}-figures-accordion`">
                                                    <template v-if="group.figures.length > 0">
                                                        <div v-for="figure of group.figures" class="card"
                                                             @mouseleave="figure.hover = false" @mouseover="figure.hover = true">
                                                            <div :data-target="`#figure-${figure.id}-hotspots`"
                                                                 class="card-header hover hover-pointer mh-btn-sm d-flex justify-content-between align-items-center"
                                                                 data-toggle="collapse"
                                                                 @click="setSelectedFigureID(figure.id)">
                                                                {{ figure.code }}

                                                                <div>
                                                                    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                                                                        <button v-show="figure.hover"
                                                                                :title="__('figure_hotspots.enqueue_ocr_process_button.title', {model: __('model.figure.name')})"
                                                                                class="btn btn-secondary btn-sm"
                                                                                type="button"
                                                                                @click.stop="enqueueOcrProcessesOfFigure(figure)">
                                                                            <i class="fas fa-redo-alt"></i>
                                                                        </button>
                                                                    </transition>

                                                                    <template v-if="figure.ocr_processes.length > 0">
                                                                        <span class="mr-3">{{ figure.ocr_processes[0].percentage || 0 }}%</span>

                                                                        <span :title="`${figure.ocr_processes.length} hotspot file(s) available.`"
                                                                              class="badge badge-primary badge-pill hover hover-pointer pt-2 pr-3 pb-2 pl-3">
                                                                            {{ figure.ocr_processes.length }}
                                                                        </span>
                                                                    </template>
                                                                </div>
                                                            </div>

                                                            <div :id="`figure-${figure.id}-hotspots`"
                                                                 :class="{show: figure.id === selectedFigureID}"
                                                                 :data-parent="`#group-${group.id}-figures-accordion`"
                                                                 class="collapse">
                                                                <div class="card-body">
                                                                    <template v-if="figure.ocr_processes.length > 0">
                                                                        <h3>{{ __('model.hotspot.name_plural', { count: figure.ocr_processes.length }) | capitalize }}</h3>

                                                                        <table class="table">
                                                                            <tbody>
                                                                                <tr v-for="ocrProcess of figure.ocr_processes">
                                                                                    <td>{{ ocrProcess.created_at | moment('Y-MM-DD HH:mm') }}</td>
                                                                                    <td>{{ ocrProcess.percentage || 0 }}%</td>
                                                                                    <td>
                                                                                        <div class="d-flex justify-content-end">
                                                                                            <div class="btn-group dropleft">
                                                                                                <button class="btn btn-light" data-toggle="dropdown" type="button">
                                                                                                    <i class="fas fa-list"></i>
                                                                                                </button>

                                                                                                <div class="dropdown-menu">
                                                                                                    <template v-if="ocrProcess.has_files_downloaded">
                                                                                                        <button class="dropdown-item" data-target="#edit-hotspots-modal"
                                                                                                                data-toggle="modal" type="button"
                                                                                                                @click="selectedOcrProcess = ocrProcess">
                                                                                                            <i class="fas fa-edit text-primary mr-2"></i>Edit
                                                                                                        </button>

                                                                                                        <a :href="ocrProcess.download_url"
                                                                                                           :title="__('figure_hotspots.download_hotspot_button.title')"
                                                                                                           class="dropdown-item"
                                                                                                           target="_blank">
                                                                                                            <i class="fas fa-download mr-2"></i>{{
                                                                                                                __('figure_hotspots.download_hotspot_button.text')
                                                                                                            }}
                                                                                                        </a>
                                                                                                    </template>
                                                                                                    <template v-else>
                                                                                                        <button :disabled="OcrApi.globalVars.isLoading"
                                                                                                                :title="__('figure_hotspots.import_button.title')"
                                                                                                                class="dropdown-item"
                                                                                                                type="button"
                                                                                                                @click="importOcrProcessFilesById(ocrProcess.id)">
                                                                                                            <i v-show="OcrApi.globalVars.isLoading"
                                                                                                               class="fas fa-circle-notch fa-spin"></i>
                                                                                                            <i class="fas fa-download text-primary mr-2"></i>{{
                                                                                                                __('figure_hotspots.import_button.text')
                                                                                                            }}
                                                                                                        </button>
                                                                                                    </template>

                                                                                                    <button :title="__('figure_hotspots.enqueue_this_ocr_process_button.title')"
                                                                                                            class="dropdown-item"
                                                                                                            type="button"
                                                                                                            @click="enqueueOcrProcess(ocrProcess, figure)">
                                                                                                        <i class="fas fa-redo-alt mr-2"></i>{{
                                                                                                            __('text.enqueue_ocr_process') | capitalize
                                                                                                        }}
                                                                                                    </button>

                                                                                                    <div class="dropdown-divider"></div>

                                                                                                    <div class="dropdown-item">
                                                                                                        <confirm-buttons
                                                                                                            :title="__('figure_hotspots.delete_hotspot_button.title')"
                                                                                                            @confirmed="deleteOcrProcess(ocrProcess.id)"></confirm-buttons>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ __('text.no_models_found', { model: __('model.hotspot.name_plural') }) | capitalize }}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        {{ __('text.no_models_found', { model: __('model.figure.name_plural') }) | capitalize }}
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    {{ __('text.no_models_found', { model: __('model.group.name_plural') }) | capitalize }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm">
                    <h3>{{ __('model.ocr_process.name_plural') | capitalize }}</h3>

                    <div class="row">
                        <div class="col">
                            <div class="scrollable scrollable-lg">
                                <template v-if="OcrApi.globalVars.isLoading && ocrProcesses.length === 0">
                                    {{ __('text.loading_models', { model: __('model.ocr_process.name_plural') }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                                </template>
                                <template v-else-if="ocrProcesses.length > 0">
                                    <ul class="list-group">
                                        <li v-for="process of ocrProcesses" :id="`ocr-process-${process.id}`"
                                            class="list-group-item d-flex justify-content-between align-items-center">
                                            {{ process.name }} <span class="text-muted">{{ process.created_at | moment('Y-MM-DD') }}</span>
                                            <div>
                                                <span :class="[`badge-${STATUS_TO_COLOR[process.status]}`]" class="badge badge-pill p-2">{{ process.status | capitalize }}</span>
                                            </div>
                                            <div v-show="process.progress < 100" class="ocr-progress-bar"></div>
                                        </li>
                                    </ul>
                                </template>
                                <template v-else>
                                    {{ __('text.no_models_found', { model: __('model.ocr_process.name_plural') }) | capitalize }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Echo from 'laravel-echo';
    import ErrorMessage from "../components/ErrorMessage";
    import EditHotspotsModal from "../components/modals/EditHotspotsModal";
    import PaginationLinks from "../components/PaginationLinks";
    import ConfirmButtons from '../components/util/ConfirmButtons.vue';
    import OcrProcessUtil from '../components/util/OcrProcessUtil.vue';

    const STATUS_TO_COLOR = {
        created: 'light',
        queued: 'warning',
        processing: 'primary',
        finished: 'success',
        aborted: 'danger',
    }

    export default {
        components: {
            ConfirmButtons,
            ErrorMessage,
            PaginationLinks,
            EditHotspotsModal,
        },
        data() {
            return {
                // Global helpers
                STATUS_TO_COLOR: STATUS_TO_COLOR,
                OCR_STATUS: OCR_STATUS,
                OcrApi: OcrApi,
                error: new _Error(),
                env: process.env.MIX_APP_ENV,

                // Fetch settings
                processingFetchInterval: 1000,
                fetchInterval: this.queuedFetchInterval,
                queuedFetchInterval: 5000,

                // Other
                ocrProcesses: [],
                selectedOcrProcess: new OcrProcess(),

                models: [],
                selectedModelID: null,
                model: new Model(),
                selectedGroupID: null,
                selectedFigureID: null,
            }
        },
        created() {
            this.fetchModels();

            // Create new Echo instance, which connects to the Explode2View OCR API
            this.createEchoInstance();

            this.fetchOcrProcesses().then(() => {
                this.connectToOcrProcessChannels(this.ocrProcesses);
            });
        },
        destroyed() {
            Explode2View_Ocr_Echo.disconnect();
        },
        methods: {
            // Fetch methods
            fetchModels() {
                this.models = [];

                axios
                    .get('/api/models')
                    .then(response => {
                        this.models = response.data.result;
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    });
            },
            fetchModel(modelId) {
                if (_.isNil(modelId)) {
                    return;
                }

                this.model = new Model();

                axios
                    .get(`/api/models/${modelId}`, {
                        params: {
                            with_figures: true,
                            with_figure_pdf: true,
                            with_ocr_processes: true,
                        },
                    })
                    .then(response => {
                        if (response.status === HTTP_OK) {
                            let models = response.data.result;

                            for (let group of models.groups) {
                                group.hover = false;

                                for (let figure of group.figures) {
                                    figure.hover = false;
                                }
                            }

                            this.model = models;
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    });
            },
            fetchOcrProcesses() {
                this.ocrProcesses = [];

                return OcrApi.getOcrProcesses({
                    non_downloaded: true,
                }).then(response => {
                    this.ocrProcesses = response.data.result;

                    this.$nextTick(() => {
                        for (let ocrProcess of this.ocrProcesses) {
                            if (ocrProcess.status !== OCR_STATUS.PROCESSING) {
                                continue;
                            }

                            this.updateProgressBar(ocrProcess);
                        }
                    });
                });
            },

            // Echo methods
            createEchoInstance() {
                window.Explode2View_Ocr_Echo = new Echo({
                    broadcaster: 'pusher',
                    key: process.env.MIX_PUSHER_APP_KEY,
                    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                    forceTLS: process.env.MIX_APP_ENV !== 'local',
                    authEndpoint: `${process.env.MIX_EXPLODE2VIEW_OCR_API_ENDPOINT}/broadcasting/auth`,
                    auth: {
                        headers: {
                            Authorization: `Bearer ${OcrApi.accessToken}`,
                        },
                    },
                });
            },
            connectToOcrProcessChannels(ocrProcesses) {
                for (let ocrProcess of ocrProcesses) {
                    if (![OCR_STATUS.QUEUED, OCR_STATUS.PROCESSING].includes(ocrProcess.status)) {
                        continue;
                    }

                    let ocrProcessChannel = `ocr-processes.${ocrProcess.id}`;

                    Explode2View_Ocr_Echo.private(ocrProcessChannel)
                        .listen('OcrProcessUpdated', (e) => {
                            if (!e.hasOwnProperty('ocrProcess')) {
                                Explode2View_Ocr_Echo.leave(ocrProcessChannel);
                                return;
                            }

                            let updatedOcrProcess = e.ocrProcess;

                            // Get ocrProcess from the ocrProcesses array, since the ocrProcess variable from the for-loop
                            // might not reference the actual ocrProcess in the ocrProcesses array anymore, if the ocrProcesses have been fetched again.
                            let ocrProcess = this.ocrProcesses.find(p => p.id === updatedOcrProcess.id);

                            if (_.isNil(ocrProcess)) {
                                Explode2View_Ocr_Echo.leave(ocrProcessChannel);
                                return;
                            }

                            this.updateObjectProperties(ocrProcess, updatedOcrProcess);
                            this.updateProgressBar(ocrProcess);

                            if (this.ocrProcessIsDone(ocrProcess)) {
                                Explode2View_Ocr_Echo.leave(ocrProcessChannel);

                                this.importOcrProcessFiles(ocrProcess);
                            }
                        });
                }
            },

            // OcrProcess CRUD methods
            enqueueOcrProcess(ocrProcess, figure) {
                if (!_.isObject(ocrProcess)) {
                    return;
                }

                if (_.isNil(ocrProcess.file_url)) {
                    this.error = new _Error(Status.WARNING, 0, this.__('error_messages.ocr_process.no_file_found'));

                    return;
                }

                axios.get(ocrProcess.file_url, {
                    responseType: 'arraybuffer',
                })
                    .then(response => {
                        let formData = new FormData();
                        formData.append('file', new Blob([response.data]), `${ocrProcess.filename}`);

                        OcrApi.createOcrProcess(formData)
                            .then(response => {
                                for (let newOcrProcess of response.data.result) {
                                    if (!_.isNil(figure.id)) {
                                        OcrProcessUtil.methods.linkOcrProcessToFigure(figure.id, newOcrProcess)
                                            .then(() => {
                                                figure.ocr_processes = figure.ocr_processes.filter(p => p.id !== newOcrProcess.id);
                                                figure.ocr_processes.unshift(newOcrProcess);
                                            });
                                    }
                                }

                                this.fetchOcrProcesses().then(() => {
                                    this.connectToOcrProcessChannels(this.ocrProcesses);
                                });
                            });
                    })
                    .catch();
            },
            enqueueOcrProcessesOfFigure(figure) {
                let hasOcrProcesses = figure.ocr_processes != null && figure.ocr_processes.length > 0;

                if (_.isNil(figure) || (!hasOcrProcesses && _.isNil(figure.pdf_url))) {
                    this.error = new _Error(Status.INFO, 0, this.capitalize(this.__('text.no_models_found', { model: this.__('model.ocr_process.name_plural') })));

                    return;
                }

                let ocrProcess = hasOcrProcesses
                    ? figure.ocr_processes[0]
                    : {
                        file_url: figure.pdf_url,
                        filename: `${figure.code}.pdf`,
                    };

                // Only enqueue newest OCR process, which is the first one since they are ordered by date
                this.enqueueOcrProcess(ocrProcess, figure);
            },
            enqueueOcrProcessesOfGroups(groups) {
                for (let group of this.asArray(groups)) {
                    for (let figure of group.figures) {
                        this.enqueueOcrProcessesOfFigure(figure);
                    }
                }
            },

            importOcrProcessFilesById(id) {
                OcrApi.getOcrProcess(id)
                    .then(response => {
                        if (response.status === HTTP_OK) {
                            let ocrProcess = response.data.result;

                            if (this.ocrProcessIsDone(ocrProcess)) {
                                this.importOcrProcessFiles(ocrProcess);
                            } else {
                                this.error = new _Error(Status.WARNING, null, this.__('error_messages.ocr_process.not_finished'));
                            }
                        }
                    })
                    .catch(() => {
                        this.error = new _Error(Status.WARNING, 404, this.__('error_messages.ocr_process.not_found'));
                    });
            },
            importOcrProcessFiles(process) {
                if (_.isEmpty(process) || process.download_url == null || process.download_url === '') {
                    return;
                }

                axios
                    .get(process.download_url, {
                        responseType: 'arraybuffer',
                    })
                    .then(response => {
                        let formData = new FormData();
                        formData.append('files[]', new Blob([response.data]), `${process.name}.zip`);

                        axios
                            .post(`/api/ocr-processes/${process.id}/files`, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            })
                            .then(response => {
                                this.error = response.data.error;

                                this.fetchModel(this.selectedModelID);
                            })
                            .catch(error => {
                                this.globalVars.globalError = this.createErrorFromRequest(error);
                            })
                        ;
                    })
                    .catch()
                ;
            },

            deleteOcrProcess(id) {
                axios.delete(`/api/ocr-processes/${id}`)
                    .then(response => {
                        this.error = response.data.error;

                        this.fetchModel(this.selectedModelID);
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    });
            },

            // Helper methods
            updateProgressBar(ocrProcess) {
                let element = $(`#ocr-process-${ocrProcess.id}`).find('.ocr-progress-bar');

                if (!_.isNil(element)) {
                    let gradient = `linear-gradient(
                            90deg,
                            rgba(0,85,160,1) 0%,
                            rgba(0,85,160,1) ${ocrProcess.progress}%,
                            rgba(224,224,224,1) ${ocrProcess.progress + 2}%,
                            rgba(255,255,255,1) 100%
                        )`;
                    element.css('background', gradient);
                }
            },
            ocrProcessIsDone(ocrProcess) {
                return ocrProcess.status === OCR_STATUS.FINISHED;
            },
            switchOcrProcess(event) {
                if (!event.hasOwnProperty('ocr_process') || !event.hasOwnProperty('direction')) {
                    return;
                }

                let nextOcrProcess = null;

                switch (event.direction) {
                    case 'next':
                        nextOcrProcess = this.findNextOcrProcess(event.ocr_process.figure_id);
                        break;
                    case 'previous':
                        nextOcrProcess = this.findPreviousOcrProcess(event.ocr_process.figure_id);
                        break;
                }

                if (!_.isNil(nextOcrProcess)) {
                    this.selectedOcrProcess = nextOcrProcess;
                }
            },
            findNextOcrProcess(curFigureID) {
                let foundCurrentFigure = false;

                for (let group of this.model.groups) {
                    for (let figure of group.figures) {
                        if (figure.id === curFigureID) {
                            foundCurrentFigure = true;
                            continue;
                        }

                        if (foundCurrentFigure && figure.ocr_processes.length > 0) {
                            return figure.ocr_processes[0];
                        }
                    }
                }

                return null;
            },
            findPreviousOcrProcess(curFigureID) {
                let previousFigure = new Figure();

                groupLoop:
                    for (let group of this.model.groups) {
                        for (let figure of group.figures) {
                            if (figure.id === curFigureID) {
                                break groupLoop;
                            } else if (figure.ocr_processes.length > 0) {
                                previousFigure = figure;
                            }
                        }
                    }

                if (previousFigure.ocr_processes.length > 0) {
                    return previousFigure.ocr_processes[0];
                }

                return null;
            },

            // We need these timeouts, since Bootstrap checks for the "show" class on the collapse div.
            // And since we are setting that class conditionally using Vue before the Bootstrap events have executed, the accordion will collapse instead of expand.
            setSelectedGroupID(groupID) {
                setTimeout(() => {
                    this.selectedGroupID = groupID;
                }, 100);
            },
            setSelectedFigureID(figureID) {
                setTimeout(() => {
                    this.selectedFigureID = figureID;
                }, 100);
            },
        },
    }
</script>
