<template>
    <div v-if="hasPermission('manage_user_roles')" class="form-group">
        <label v-if="showLabel" class="required">{{ __('model.company.name', { count: 2 }) | capitalize }}</label>
        <div class="custom-control custom-checkbox" v-for="company of filteredCompanies">
            <input type="checkbox" :id="id + '-' + company.id" class="custom-control-input" :disabled="globalVars.isLoading"
                   :value="company.id" v-model="selectedCompanyIDs" @change="$emit('update:selected', selectedCompanyIDs)">
            <label class="custom-control-label" :for="id + '-' + company.id">{{ company.name }}</label>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectedCompanyIDs: null,
                filteredCompanies: [],
            }
        },
        props: {
            companies: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            loadCompanies: {
                type: Boolean,
                default: true,
            },
            selected: {
                type: Array|Number,
                default: function () {
                    return [];
                },
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            id: function () {
                return this.randomString();
            },
        },
        watch: {
            companies: function () {
                this.filteredCompanies = this.companies;
            },
            selected: function () {
                this.selectedCompanyIDs = _.isArray(this.selected) ? this.selected : [this.selected];
            },
        },
        created() {
            this.selectedCompanyIDs = _.isArray(this.selected) ? this.selected : [this.selected];

            if (this.loadCompanies) {
                this.getCompanies();
            } else {
                this.filteredCompanies = this.companies;
            }
        },
        methods: {
            getCompanies() {
                this.filteredCompanies = [];

                axios
                    .get('/api/companies')
                    .then(response => {
                        if (!_.isEmpty(response) && response.status === HTTP_OK && !_.isNil(response.data.result)) {
                            this.filteredCompanies = response.data.result;
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
                ;
            },
        },
    }
</script>
