<template>
    <div v-if="hasPermission('manage_user_roles')" class="form-group">
        <label v-if="showLabel" :for="id" class="required">{{ __('model.role.name') | capitalize }}</label>
        <select :id="showLabel ? id : null" class="form-control" :title="__('model.role.name') | capitalize"
                v-model="selectedRoleID" @change="$emit('update:selected', selectedRoleID)" :disabled="globalVars.isLoading" required>
            <option :value="null">{{ __('text.select_item') }}</option>
            <option v-for="role of filteredRoles" :value="role.id">{{ role.name | snakeToTitle }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectedRoleID: null,
                filteredRoles: [],
            }
        },
        props: {
            roles: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            loadRoles: {
                type: Boolean,
                default: true,
            },
            selected: {
                type: Number,
                default: null,
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            id: function () {
                return this.randomString();
            },
        },
        watch: {
            roles: function () {
                this.filteredRoles = this.roles;
            },
            selected: function () {
                this.selectedRoleID = this.selected;
            },
        },
        created() {
            this.selectedRoleID = this.selected;

            if (this.loadRoles) {
                this.getRoles();
            } else {
                this.filteredRoles = this.roles;
            }
        },
        methods: {
            getRoles() {
                this.filteredRoles = [];

                axios
                    .get('/api/roles')
                    .then(response => {
                        if (!_.isEmpty(response) && response.status === HTTP_OK && !_.isNil(response.data.result)) {
                            this.filteredRoles = response.data.result;
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
                ;
            },
        },
    }
</script>
