<template>
    <div class="modal fade" id="link-groups-to-figure-modal" tabindex="-1" role="dialog" aria-labelledby="link-groups-to-figure-modal"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('link_groups.header') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="link-groups-form" action="" method="post" @submit.prevent="linkGroups">
                    <div class="modal-body">
                        <error-message :error="error"></error-message>

                        <div class="form-group" v-if="parentModels != null && parentModels.length > 0">
                            <label for="select-model">{{ __('model.model.name') | capitalize }}</label>
                            <select id="select-model" class="form-control" v-model="selectedModelID"
                                    @change="[filterAlreadyLinkedGroups(allGroups, selectedModelID), emitEvent('model-selected', selectedModelID)]">
                                <option value="0" disabled>{{ __('text.select_item') }}</option>
                                <option v-for="model of parentModels" :value="model.id">{{ model.number }}: {{ model.name }}</option>
                            </select>
                        </div>

                        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                            <div v-if="selectedModelID === 0" key="selectModel">
                                {{ __('link_groups.text.select_model') }}
                            </div>
                            <div key="filteredGroups" class="form-group" v-else-if="filteredGroups != null && filteredGroups.length > 0">
                                <label>{{ __('model.group.name', { count: 2 }) | capitalize }}</label>
                                <transition-group name="list-complete" tag="div">
                                    <div class="custom-control custom-checkbox list-complete-item" v-for="group in filteredGroups" :key="group.id">
                                        <input type="checkbox" :id="'link-check-' + group.id" class="custom-control-input" name="select-group"
                                               v-model="selectedGroupIDs" :value="group.id">
                                        <label :for="'link-check-' + group.id" class="custom-control-label">{{ group.number }}: {{ group.name }}</label>
                                    </div>
                                </transition-group>
                            </div>
                            <div v-else-if="globalVars.isLoading" key="loading-groups">
                                {{ __('text.loading_models', { model: __('model.group.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                            </div>
                            <div key="no-filtered-groups" v-else>
                                {{ __('link_groups.text.already_linked') }}
                            </div>
                        </transition>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('button.close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading">{{ __('link_groups.button.save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from "../ErrorMessage";
    import {EventBus} from "../../eventbus";

    export default {
        components: {
            ErrorMessage,
        },
        data() {
            return {
                error: new _Error(),
                allGroups: [],
                filteredGroups: [],
                selectedModelID: 0,
                selectedGroupIDs: [],
            }
        },
        props: {
            parentModels: Array,
        },
        created() {
            let self = this;

            EventBus.$on('invalidate-groups', () => {
                this.allGroups = [];
            });

            EventBus.$on('model-selected', (modelID) => {
                if (!_.isNil(modelID) && _.isInteger(modelID)) {
                    this.selectedModelID = modelID;

                    if (_.isEmpty(this.allGroups) && !this.globalVars.isLoading) {
                        this.getAllGroups().then(() => {
                            this.filterAlreadyLinkedGroups(this.allGroups, modelID);
                        });
                    } else {
                        this.filterAlreadyLinkedGroups(this.allGroups, modelID);
                    }
                }
            });

            $(function () {
                $('#link-groups-to-figure-modal').on('hidden.bs.modal', () => {
                    self.error = new _Error();
                    self.selectedGroupIDs = [];
                    self.resetForm('link-groups-form');
                });
            });
        },
        destroyed() {
            EventBus.$off('model-selected');
            EventBus.$off('invalidate-groups');
        },
        methods: {
            async getAllGroups() {
                await axios
                    .get('/api/groups', {
                        params: {
                            with_names: true,
                        }
                    })
                    .then(response => {
                        if (!_.isEmpty(response)) {
                            this.allGroups = response.data.result;
                        }
                    })
                    .catch(error => {
                        this.error = this.createErrorFromRequest(error);
                    })
                ;
            },
            linkGroups() {
                if (this.selectedModelID && _.isNumber(this.selectedModelID) && this.selectedGroupIDs && this.selectedGroupIDs.length > 0) {
                    axios
                        .put('/api/models/' + this.selectedModelID + '/groups', {
                            group_ids: this.selectedGroupIDs,
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;
                                this.selectedGroupIDs = [];

                                if (response.status === HTTP_CREATED) {
                                    this.filteredGroups = [];

                                    this.emitEvent('get-models', {
                                        selectedModelID: this.selectedModelID,
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            filterAlreadyLinkedGroups(allGroups, modelID) {
                if (modelID && _.isNumber(modelID)) {
                    let selectedFrom = this.getItemFromArrayByID(this.parentModels, modelID);

                    if (!_.isNil(allGroups) && !_.isNil(selectedFrom)) {
                        this.filteredGroups = allGroups.filter(g => {
                            let curGroup = _.find(selectedFrom.groups, {id: g.id});
                            return !_.isNil(curGroup) ? g.id !== curGroup.id : true;
                        });
                    }

                    this.selectedGroupIDs = [];
                }
            },
        },
    }
</script>