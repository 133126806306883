<template>
    <div class="row">
        <div class="col">
            <error-message :error="error" :toast="true" positioning="floating" :timeout="true" classes="mt-3"
                           enter-animation="slideInLeft" leave-animation="slideOutLeft"></error-message>

            <div class="row">
                <div class="col">
                    <h2 class="mb-0">{{ __('account.manage.user.header') }}</h2>
                    <p>{{ __('account.manage.user.page_info') }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <form @submit.prevent="saveUser" class="mb-3">
                        <div class="row">
                            <div class="col-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="card-title">
                                            <h3 class="m-0">{{ __('account.manage.user.information.header') }}</h3>
                                            <p>{{ __('account.manage.user.information.page_info') }}</p>
                                        </div>

                                        <hr>

                                        <div class="form-group">
                                            <label for="user-name" class="required">{{ __('model.user.attr.name') | capitalize }}</label>
                                            <input type="text" id="user-name" class="form-control" v-model="user.name" required>
                                        </div>
                                        <div class="form-group">
                                            <label for="user-email" class="required">{{ __('model.user.attr.email') | capitalize }}</label>
                                            <input type="text" id="user-email" class="form-control" v-model="user.email" required>
                                        </div>
                                        <div class="form-group">
                                            <label for="user-password" :class="{required: id == null}">{{ __('model.user.attr.password') | capitalize }}</label>
                                            <input type="password" id="user-password" class="form-control" v-model="user.password" :required="id == null">
                                        </div>
                                        <div class="form-group">
                                            <label for="user-password_confirmation" :class="{required: id == null}">{{ __('model.user.attr.password_confirmation') | capitalize }}</label>
                                            <input type="password" id="user-password_confirmation" class="form-control" v-model="user.password_confirmation" :required="id == null">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="card-title">
                                            <h3 class="m-0">{{ __('account.manage.user.settings.header') }}</h3>
                                            <p>{{ __('account.manage.user.settings.page_info') }}</p>
                                        </div>

                                        <div class="form-group">
                                            <label for="user-lang" class="required">{{ __('model.user.attr.language') | capitalize }}</label>
                                            <select id="user-lang" class="form-control" v-model="user.lang" required>
                                                <option value="">{{ __('text.select_item') }}</option>
                                                <option v-for="LANG of LANGUAGES" :value="LANG">{{ LANG.toUpperCase() }}</option>
                                            </select>
                                        </div>

                                        <select-role :selected.sync="user.role_id"></select-role>

                                        <select-companies :selected.sync="user.company_ids"></select-companies>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <button type="submit" class="btn btn-primary mt-3" :disabled="globalVars.isLoading">{{ __('button.save') | capitalize }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from "../../components/ErrorMessage";
    import SelectRole from "../../components/util/SelectRole";
    import SelectCompanies from "../../components/util/SelectCompanies";

    export default {
        components: {
            ErrorMessage,
            SelectRole,
            SelectCompanies,
        },
        data() {
            return {
                error: new _Error(),
                user: new User(),
                LANGUAGES: LANGUAGES,
            }
        },
        props: {
            id: Number | String,
        },
        created() {
            this.getUser(this.id);
        },
        methods: {
            getUser(userID) {
                if (!_.isNil(userID)) {
                    axios
                        .get('/api/users/' + userID, {
                            params: {
                                with_companies: true,
                            }
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                if (!_.isNil(response.data.result)) {
                                    this.user = response.data.result;

                                    this.$set(this.user, 'company_ids', _.map(this.user.companies, 'id'));
                                } else {
                                    this.globalVars.globalError = response.data.error;
                                    this.$router.push({name: 'manage_users'});
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            saveUser() {
                if (!_.isEmpty(this.user.company_ids)) {
                    axios
                        .put('/api/users' + (!_.isNil(this.id) ? '/' + this.id : ''), {
                            user: this.user,
                        })
                        .then(response => {
                            if (!_.isNil(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_CREATED) {
                                    this.$router.push({name: 'create_edit_user', params: {id: response.data.result.id}});
                                } else {
                                    this.getUser(response.data.result.id);
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                } else {
                    this.error = new _Error(Status.WARNING, 0, this.__('validation.company_required'));
                }
            },
        },
    }
</script>
