<template>
    <div class="modal fade" id="create-figure-modal" tabindex="-1" role="dialog" aria-labelledby="create-figure-modal" aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('model.figure.name') | capitalize }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form id="create-new-figure-form" action="" method="post" @submit.prevent="createNewFigure">
                    <div class="modal-body">
                        <error-message :error="error"></error-message>

                        <div class="form-group" v-if="parentGroups != null && parentGroups.length > 0">
                            <label for="select-group">{{ __('model.group.name') | capitalize }}</label>
                            <select id="select-group" class="form-control" v-model="selectedGroupID" required
                                    @change="emitEvent('get-figures', selectedGroupID)">
                                <option :value="0" disabled>{{ __('text.select_item') }}</option>
                                <option v-for="group in parentGroups" :value="group.id">
                                    {{ group.number }}: {{ group.name }}
                                </option>
                            </select>
                        </div>

                        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                            <div v-if="newFigure != null && selectedGroupID">
                                <div class="form-group">
                                    <label for="figure-number">{{ __('model.figure.name') | capitalize }} {{ __('model.figure.attr.number') }}</label>
                                    <input type="number" id="figure-number" class="form-control" min="100" required
                                           :title="__('title.input.model_field', {
                                               field: __('model.figure.attr.number'),
                                               model: __('model.figure.name')
                                           }) | capitalize"
                                           :placeholder="__('model.figure.attr.number') | capitalize"
                                           v-model="newFigure.number" @input="checkIfPrePostSituation(figureCode)">
                                </div>
                                <div class="form-group">
                                    <label for="figure-code">{{ __('model.figure.name') | capitalize }} {{ __('model.figure.attr.code') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ figureSignature }}</span>
                                        </div>
                                        <input type="text" id="figure-code" class="form-control text-uppercase" placeholder="Code" required
                                               v-model="figureVersionCode" @input="checkIfPrePostSituation(figureCode)"
                                               pattern="[0-9]{2}-[0-9]{2}[A-Z]"
                                               :title="__('title.input.model_field', {
                                                   field: __('model.figure.attr.code'),
                                                   model: __('model.figure.name')
                                               }) | capitalize"
                                               :placeholder="__('model.figure.attr.code') | capitalize">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="figure-name">{{ __('model.figure.name') | capitalize }} {{ __('model.figure.attr.name') }}</label>
                                    <input type="text" v-model="newFigure.name" id="figure-name" class="form-control" required
                                           :title="__('title.input.model_field', {
                                               field: __('model.figure.attr.name'),
                                               model: __('model.figure.name')
                                           }) | capitalize"
                                           :placeholder="__('model.figure.attr.name') | capitalize">
                                </div>

                                <transition enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown">
                                    <div v-if="prePostFigures != null && prePostFigures.length > 0">
                                        <div class="form-inline mb-2" v-for="figure of prePostFigures">
                                            {{ figure.code === newFigure.code ? __('create_figure.text.current_figure') : figure.code }}:&nbsp;

                                            <div class="form-group">
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" :id="'pre-' + figure.id" class="custom-control-input" :checked="figure.is_pre" required
                                                           :name="'is-pre-or-post-' + figure.id" @change="[figure.is_pre = true, figure.is_post = false]">
                                                    <label class="custom-control-label" :for="'pre-' + figure.id">Pre</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" :id="'post-' + figure.id" class="custom-control-input" :checked="figure.is_post" required
                                                           :name="'is-pre-or-post-' + figure.id" @change="[figure.is_pre = false, figure.is_post = true]">
                                                    <label class="custom-control-label" :for="'post-' + figure.id">Post</label>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label :for="'vin-' + figure.id" class="mr-2">VIN</label>
                                                <input type="text" :id="'vin-' + figure.id" class="form-control" required v-model="figure.VIN">
                                            </div>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                        </transition>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('button.close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="globalVars.isLoading || !selectedGroupID">
                            {{ __('button.save', { model: __('model.figure.name') }) | capitalize }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
    import {EventBus} from "../../eventbus";
    import ErrorMessage from "../ErrorMessage";

    export default {
        components: {
            ErrorMessage,
        },
        data() {
            return {
                StatusMapper: StatusMapper,
                error: new _Error(),
                newFigure: new Figure(),
                figureVersionCode: '',
                selectedGroupID: 0,
                prePostFigures: [],
            }
        },
        props: {
            selectedModelId: Number,
            selectedModelNumber: String,
            selectedGroupNumber: String,
            parentGroups: Array,
            parentFigures: Array,
        },
        computed: {
            figureSignature: function () {
                return this.selectedModelNumber + '-' + this.selectedGroupNumber + '-' + (this.newFigure.number || '(...)');
            },
            figureCode: function () {
                return (this.figureSignature + '-' + this.figureVersionCode).toUpperCase();
            },
        },
        watch: {
            'figureVersionCode': function () {
                this.newFigure.code = this.figureCode;
            },
            'newFigure.number': function () {
                this.newFigure.code = this.figureCode;
            },
        },
        created() {
            let self = this;

            EventBus.$on('model-selected', () => {
                this.selectedGroupID = 0;
            });

            EventBus.$on('group-selected', (groupID) => {
                if (!_.isNil(groupID) && _.isNumber(groupID)) {
                    this.selectedGroupID = groupID;
                }
            });

            $(function () {
                $('#create-figure-modal').on('hidden.bs.modal', function () {
                    self.error = new _Error();
                    self.newFigure = new Figure();
                    self.figureVersionCode = '';
                    self.prePostFigures = [];
                    self.resetForm('create-new-figure-form');
                });
            });
        },
        destroyed() {
            EventBus.$off('model-selected');
            EventBus.$off('group-selected');
        },
        methods: {
            createNewFigure() {
                this.error = new _Error();

                if (!_.isNil(this.selectedModelId) && _.isNumber(this.selectedModelId)
                    && !_.isNil(this.selectedGroupID) && _.isNumber(this.selectedGroupID)
                    && !_.isNil(this.newFigure) && !this.hasEmptyAttributes(this.newFigure, ['id', 'number', 'code', 'name'])
                ) {
                    let figures = !_.isEmpty(this.prePostFigures) ? this.prePostFigures : [this.newFigure];

                    axios
                        .put('/api/models/' + this.selectedModelId + '/groups/' + this.selectedGroupID + '/figures', {
                            figures: figures,
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    this.newFigure = new Figure();
                                    this.figureVersionCode = '';
                                    this.prePostFigures = [];
                                    this.resetForm('create-new-figure-form');

                                    this.emitEvent('get-models', {
                                        selectedModelID: this.selectedModelId,
                                        selectedGroupID: this.selectedGroupID,
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            checkIfPrePostSituation(fullFigureCode) {
                if (!_.isNil(fullFigureCode)) {
                    this.prePostFigures = [];
                    this.newFigure.is_pre = false;
                    this.newFigure.is_post = false;
                    this.newFigure.VIN = '';

                    let splicedFigureCode = this.getSplicedFigureCode(fullFigureCode);
                    if (!_.isEmpty(splicedFigureCode)) {
                        let highestRevisionLetter = splicedFigureCode.revisionLetter;
                        let figureCode = [
                            splicedFigureCode.modelNumber, splicedFigureCode.groupNumber, splicedFigureCode.figureNumber,
                            splicedFigureCode.versionNumber, splicedFigureCode.variantNumber,
                        ].join('-');

                        // Compare the given figureCode with the other Figures
                        for (let figure of this.parentFigures) {
                            let nextSplicedFigureCode = this.getSplicedFigureCode(figure.code);
                            if (!_.isEmpty(nextSplicedFigureCode)) {
                                let nextFigureCode = [
                                    nextSplicedFigureCode.modelNumber, nextSplicedFigureCode.groupNumber, nextSplicedFigureCode.figureNumber,
                                    nextSplicedFigureCode.versionNumber, nextSplicedFigureCode.variantNumber,
                                ].join('-');

                                if (nextFigureCode === figureCode && nextSplicedFigureCode.revisionLetter !== splicedFigureCode.revisionLetter) {
                                    figure.revisionLetter = nextSplicedFigureCode.revisionLetter;
                                    this.prePostFigures.push(figure);

                                    // Calculate the highest revisionLetter so it can be used later to calculate the 'is_pre' and 'is_post' of the Figures
                                    if (nextSplicedFigureCode.revisionLetter > highestRevisionLetter) {
                                        highestRevisionLetter = nextSplicedFigureCode.revisionLetter;
                                    }

                                    // Set the VIN of the newFigure to the already existing VIN for better user-experience
                                    if (!_.isNil(figure.VIN)) {
                                        this.newFigure.VIN = figure.VIN;
                                    }
                                }
                            }
                        }

                        if (!_.isEmpty(this.prePostFigures)) {
                            this.newFigure.revisionLetter = splicedFigureCode.revisionLetter;
                            this.prePostFigures.push(this.newFigure);

                            // Calculate which Figures are Pre's  and which ones are Post's
                            for (let figure of this.prePostFigures) {
                                figure.is_pre = figure.revisionLetter < highestRevisionLetter;
                                figure.is_post = figure.revisionLetter === highestRevisionLetter;
                            }
                        }
                    }
                }
            },
            getSplicedFigureCode(fullFigureCode) {
                if (!_.isNil(fullFigureCode) && _.isString(fullFigureCode)) {
                    let splicedFigureCode = fullFigureCode.split('-');
                    let modelNumber = splicedFigureCode[0] ?? '';
                    let groupNumber = splicedFigureCode[1] ?? '';
                    let figureNumber = splicedFigureCode[2] ?? '';
                    let versionNumber = splicedFigureCode[3] ?? '';
                    let variantNumberWithRevisionLetter = splicedFigureCode[4] ?? '';
                    let variantNumber = '';
                    let revisionLetter = '';
                    let VIN = splicedFigureCode[5] ?? '';

                    if (!_.isNil(variantNumberWithRevisionLetter) && variantNumberWithRevisionLetter.length === 3) {
                        variantNumber = variantNumberWithRevisionLetter.slice(0, variantNumberWithRevisionLetter.length - 1);
                        revisionLetter = variantNumberWithRevisionLetter.slice(variantNumberWithRevisionLetter.length - 1, variantNumberWithRevisionLetter.length);
                    }

                    return {
                        modelNumber: modelNumber, groupNumber: groupNumber, figureNumber: figureNumber,
                        versionNumber: versionNumber, variantNumber: variantNumber, revisionLetter: revisionLetter,
                        VIN: VIN,
                    };
                }

                return {};
            },
        }
    }
</script>
