<template>
    <div class="row">
        <div class="col">
            <error-message :error="error" :timeout="timeout" :toast="true" positioning="fixed" enter-animation="fadeInLeft" leave-animation="fadeOutLeft"
                           classes="mt-3"></error-message>

            <div class="row">
                <div class="col">
                    <h1>{{ __('model.translation.name', { count: 2 }) | capitalize }}</h1>
                    <p>{{ __('translations.text.page_info') }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="row mb-3">
                        <div class="col-md">
                            <div class="d-inline">{{ __('translations.title.filter') }}</div>

                            <div class="form-inline">
                                <div class="form-inline mr-2">
                                    <div v-for="FILTER in FILTERS" class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" :id="FILTER.id" class="custom-control-input" :disabled="globalVars.isLoading"
                                               name="filter" :value="FILTER.id" v-model="selectedFilter" @change="filterTranslations">
                                        <label class="custom-control-label" :for="FILTER.id">{{ FILTER.title }}</label>
                                    </div>
                                </div>

                                <div class="form-inline mt-2 mt-xl-0 mr-2">
                                    <div class="form-group mr-3">
                                        <label for="filter-language" class="mr-2">{{ __('label.language') }}</label>
                                        <select id="filter-language" class="form-control" v-model="selectedLanguage" :disabled="globalVars.isLoading" @change="filterTranslations">
                                            <option value="">{{ __('text.select_item') }}</option>
                                            <option v-for="language of languagesWithoutCurrent" :value="language">{{ language.toUpperCase() }}</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <div class="input-group">
                                            <input type="text" class="form-control"
                                                   :title="__('translations.title.input.filters.translation')" :placeholder="__('translations.title.input.filters.translation')"
                                                   v-model="searchTranslation" @keydown.enter="filterTranslations" :disabled="globalVars.isLoading">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-outline-secondary border-left-0 border" :disabled="globalVars.isLoading"
                                                        @click="[searchTranslation = '', filterTranslations()]">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-inline mt-2 mt-xl-0 mr-2">
                                    <button type="button" class="btn btn-primary mr-2" :disabled="globalVars.isLoading" @click="filterTranslations">
                                        {{ __('button.filters.apply') }} <i class="fas fa-search"></i>
                                    </button>
                                    <button type="button" class="btn btn-primary" :disabled="globalVars.isLoading" @click="removeFilters">
                                        {{ __('button.filters.clear') }} <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-auto mt-3">
                            <button type="button" class="btn btn-primary mr-1"
                                    :title="__('button.reload', { model: __('model.translation.name', { count: 2 }) }) | capitalize"
                                    :disabled="globalVars.isLoading" @click="filterTranslations">
                                <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading }"></i>
                            </button>
                            <button type="button" class="btn btn-primary" :title="__('translations.title.button.translations.save')"
                                    @click="saveTranslations()" :disabled="globalVars.isLoading">
                                {{ __('translations.title.button.translations.save') }} <i class="fas fa-save"></i>
                            </button>
                        </div>
                    </div>

                    <pagination-links :pagination="pagination" @switch="getTranslations($event.url)"></pagination-links>

                    <div class="row">
                        <div class="col">
                            <table id="translations-table" class="table">
                                <thead class="thead-light sticky-top">
                                <tr>
                                    <th>ID</th>
                                    <th v-for="language in filteredLanguages">{{ language.toUpperCase() }}</th>
                                </tr>
                                </thead>
                                <list-staggered tag="tbody" :disable-leave-animation="true">
                                    <template v-if="translations != null && translations.length > 0">
                                        <tr v-for="( translation, index ) in translations" :key="translation.id" :data-index="index">
                                            <td>{{ translation.translationable != null
                                                ? translation.translationable.code || translation.translationable.number || translation.translationable.heading
                                                : '' }}
                                            </td>
                                            <td v-for="language in filteredLanguages">
                                                <div class="input-group">
                                                    <input typeof="text" class="form-control" :title="__('translations.title.input.translations.edit')"
                                                           v-model="translation[language].translation" @input="setUpdated(translation.id, language)"
                                                           @keydown.enter="saveTranslations(translation, language)">

                                                    <transition enter-active-class="animated slideInRight"
                                                                leave-active-class="animated slideOutRight">
                                                        <div class="input-group-append" v-if="translation[language].updated">
                                                            <button type="button" class="btn btn-primary btn-sm float-right"
                                                                    :title="__('translations.title.button.translations.undo')"
                                                                    @click="undoEdit(translation.id, language)">
                                                                <i class="fas fa-undo"></i>
                                                            </button>
                                                            <button type="button" class="btn btn-primary btn-sm float-right"
                                                                    :title="__('button.save', { model: __('model.translation.name') }) | capitalize"
                                                                    @click="saveTranslations(translation, language)">
                                                                <i class="fas fa-save"></i>
                                                            </button>
                                                        </div>
                                                    </transition>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-else-if="globalVars.isLoading" key="loading">
                                        <td>
                                            <p>{{ __('text.loading_models', { model: __('model.translation.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i></p>
                                        </td>
                                    </tr>
                                    <tr v-else key="noTranslations">
                                        <td>
                                            <p>{{ __('text.no_models_found', { model: __('model.translation.name', { count: 2 }) }) | capitalize }}</p>
                                        </td>
                                    </tr>
                                </list-staggered>
                            </table>
                        </div>
                    </div>

                    <pagination-links :pagination="pagination" @switch="getTranslations($event.url)"></pagination-links>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ListStaggered from "../transitions/ListStaggered";
    import ErrorMessage from "../components/ErrorMessage";
    import PaginationLinks from "../components/PaginationLinks";

    export default {
        components: {
            ListStaggered,
            ErrorMessage,
            PaginationLinks,
        },
        data() {
            return {
                filteredLanguages: [],
                error: new _Error(),
                timeout: true,
                translations: [],
                editTranslation: [],
                pagination: new Pagination(),
                FILTERS: {
                    ALL: {
                        id: 'ALL',
                        title: this.__('translations.title.input.filters.all'),
                    },
                    NON_TRANSLATED: {
                        id: 'NON_TRANSLATED',
                        title: this.__('translations.title.input.filters.non_translated'),
                    },
                    GROUPS_AND_FIGURES: {
                        id: 'GROUPS_AND_FIGURES',
                        title: this.__('translations.title.input.filters.groups_and_figures'),
                    },
                    COLUMN_HEADINGS: {
                        id: 'COLUMN_HEADINGS',
                        title: this.__('translations.title.input.filters.column_headings'),
                    },
                },
                selectedFilter: null,
                selectedLanguage: '',
                searchTranslation: '',
            }
        },
        created() {
            this.getTranslations();
            this.selectedFilter = this.FILTERS.ALL.id;
            this.filteredLanguages = this.languages;
        },
        computed: {
            languages: function () {
                let languages = LANGUAGES.filter((lang) => lang !== this.globalVars.lang);
                languages.unshift(this.globalVars.lang);
                return languages;
            },
            languagesWithoutCurrent: function () {
                return LANGUAGES.filter((lang) => lang !== this.globalVars.lang);
            },
        },
        methods: {
            filterTranslations() {
                let filters = {};

                if (!_.isEmpty(this.selectedFilter) && this.selectedFilter !== this.FILTERS.ALL.id) {
                    filters[this.selectedFilter.toLowerCase()] = '';
                }

                if (!_.isEmpty(this.selectedLanguage)) {
                    filters['lang'] = this.selectedLanguage;
                    this.filteredLanguages = [];
                    this.filteredLanguages.push(this.globalVars.lang, this.selectedLanguage);
                } else {
                    this.filteredLanguages = this.languages;
                }

                if (this.searchTranslation) {
                    filters['search'] = this.searchTranslation;
                }

                this.getTranslations('', filters);
            },
            getTranslations(url = '', params = {}) {
                this.translations = [];
                this.timeout = true;
                this.error = new _Error();

                if (!url) {
                    url = '/api/translations';
                    params['with_identifiers'] = '';
                }

                axios
                    .get(url, {params})
                    .then(response => {
                        if (!_.isEmpty(response)) {
                            if (response.status === HTTP_OK) {
                                this.pagination = response.data.result;

                                for (let translation of this.pagination.data) {
                                    for (let language of this.filteredLanguages) {
                                        translation[language] = {
                                            translation: translation[language],
                                            old_translation: translation[language],
                                            updated: false,
                                        };
                                    }

                                    this.translations.push(translation);
                                }
                            }
                        }
                    })
                    .catch(error => {
                        this.timeout = false;
                        this.error = this.createErrorFromRequest(error);
                    })
                ;
            },
            saveTranslations(translation = null, language = null) {
                if (!_.isEmpty(this.translations)) {
                    let translationsToSave = [];

                    if (!_.isNil(translation) && !_.isNil(language)) {
                        let newTranslation = new Translation(translation.id);
                        newTranslation[language] = translation[language].translation;
                        newTranslation.translationable.identifier = translation.translationable.code || translation.translationable.number || translation.translationable.heading;

                        translationsToSave.push(newTranslation);
                    } else {
                        for (let translation of this.translations) {
                            let updated = false;
                            let newTranslation = new Translation(translation.id);
                            newTranslation.translationable.identifier = translation.translationable.code || translation.translationable.number || translation.translationable.heading;

                            for (let language of this.filteredLanguages) {
                                if (translation[language].updated) {
                                    updated = true;
                                    newTranslation[language] = translation[language].translation;
                                }
                            }

                            if (updated) {
                                translationsToSave.push(newTranslation);
                            }
                        }
                    }

                    if (!_.isEmpty(translationsToSave)) {
                        axios
                            .put('/api/translations', {
                                translations: translationsToSave,
                            })
                            .then(response => {
                                if (!_.isEmpty(response)) {
                                    this.timeout = true;
                                    this.error = response.data.error;

                                    if (response.status === HTTP_OK) {
                                        for (let translation of translationsToSave) {
                                            let actualTranslationObject = this.getItemFromArrayByID(this.translations, translation.id);
                                            if (!_.isNil(actualTranslationObject)) {
                                                for (let language of this.filteredLanguages) {
                                                    if (!_.isNil(translation[language])) {
                                                        actualTranslationObject[language].translation = translation[language];
                                                        actualTranslationObject[language].old_translation = translation[language];
                                                        actualTranslationObject[language].updated = false;
                                                    }
                                                }
                                            }
                                        }
                                    } else if (response.status === HTTP_RESET_CONTENT) {
                                        this.searchTranslation = translationsToSave[0].translationable.identifier;

                                        this.filterTranslations();
                                    }
                                }
                            })
                            .catch(error => {
                                this.timeout = false;
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    } else {
                        this.error.severity = Status.INFO;
                        this.error.message = 'Er zijn geen vertalingen aangepast.';
                    }
                }
            },
            setUpdated(translationID, language) {
                if (!_.isNil(translationID) && _.isInteger(translationID) && !_.isNil(language)) {
                    let translation = this.getItemFromArrayByID(this.translations, translationID);
                    if (!_.isNil(translation)) {
                        translation[language].updated = true;
                    }
                }
            },
            undoEdit(translationID, language) {
                if (!_.isNil(translationID) && _.isInteger(translationID) && !_.isNil(language)) {
                    let translation = this.getItemFromArrayByID(this.translations, translationID);
                    if (!_.isNil(translation)) {
                        translation[language].translation = translation[language].old_translation;
                        translation[language].updated = false;
                    }
                }
            },
            removeFilters() {
                this.selectedLanguage = '';
                this.selectedFilter = this.FILTERS.ALL.id;
                this.searchTranslation = '';
                this.filterTranslations();
            },
        }
    }
</script>
