<template>
    <div class="row">
        <div class="col">
            <error-message :error="error" :toast="true" positioning="floating" :timeout="true" classes="mt-3"
                           enter-animation="slideInLeft" leave-animation="slideOutLeft"></error-message>

            <div class="row">
                <div class="col">
                    <h2 class="d-inline-block">{{ __('account.manage.users.header') }}</h2>
                    <button type="button" class="btn btn-primary btn-sm"
                            :title="__('button.reload', { model: __('model.user.name', { count: 2 }) }) | capitalize"
                            @click="getUsers">
                        <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading }"></i>
                    </button>
                    <router-link :to="{ name: 'create_edit_user' }" class="btn btn-primary btn-sm" :title="__('account.manage.users.title.button.create')">
                        <i class="fas fa-plus"></i>
                    </router-link>

                    <p>{{ __('account.manage.users.page_info') }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col scrollable scrollable-lg">
                    <table class="table">
                        <thead class="thead-light sticky-top">
                        <tr>
                            <th>{{ __('model.user.attr.name') | capitalize }}</th>
                            <th>{{ __('model.user.attr.email') | capitalize }}</th>
                            <th v-if="hasPermission('manage_user_roles')">{{ __('model.role.name') | capitalize }}</th>
                            <th v-if="hasPermission('manage_user_companies')">{{ __('model.company.name', { count: 2 }) | capitalize }}</th>
                            <th></th>
                            <th v-if="hasPermission('login_as')"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="users != null && users.length > 0">
                            <tr v-for="user of users">
                                <td>{{ user.name }}</td>
                                <td>{{ user.email }}</td>
                                <td v-if="hasPermission('manage_user_roles')">
                                    <select-role :selected.sync="user.role_id" :roles="roles" :load-roles="false" :show-label="false"></select-role>
                                </td>
                                <td v-if="hasPermission('manage_user_companies')">
                                    <select-companies :selected.sync="user.company_ids" :companies="companies" :load-companies="false" :show-label="false"></select-companies>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-success" :title="__('button.save') | capitalize" @click="saveUser(user)">
                                        <i class="fas fa-save"></i>
                                    </button>
                                    <router-link v-if="hasPermission('create_edit_user')" :to="{ name: 'create_edit_user', params: { id: user.id } }"
                                                 class="btn btn-secondary" :title="__('button.edit') | capitalize">
                                        <i class="fas fa-edit"></i>
                                    </router-link>
                                    <confirm-dialog v-if="hasPermission('user_delete')" :title="__('button.delete') | capitalize"
                                                    :data="user.id" @confirmed="deleteUser"></confirm-dialog>
                                </td>
                                <td v-if="hasPermission('login_as')">
                                    <form action="/loginAs" method="post" class="w-auto">
                                        <input type="hidden" name="_token" :value="globalVars.csrf_token">
                                        <input type="hidden" name="user_id" :value="user.id">
                                        <button type="submit" class="btn btn-primary" :title="__('account.manage.users.title.button.login_as') | capitalize">
                                            <i class="fas fa-sign-in-alt"></i>
                                        </button>
                                    </form>
                                </td>
                            </tr>
                        </template>
                        <template v-else-if="globalVars.isLoading">
                            <tr>
                                <td>{{ __('text.loading_models', { model: __('model.user.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i></td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td>{{ __('text.no_models_found', { model: __('model.user.name', { count: 2 }) }) }}</td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from "../../components/ErrorMessage";
    import SelectRole from "../../components/util/SelectRole";
    import SelectCompanies from "../../components/util/SelectCompanies";
    import ModelCrudButtons from "../../components/ModelCrudButtons";
    import ConfirmDialog from "../../components/util/ConfirmDialog";

    export default {
        components: {
            ErrorMessage,
            SelectRole,
            SelectCompanies,
            ModelCrudButtons,
            ConfirmDialog,
        },
        data() {
            return {
                error: new _Error(),
                users: [],
                roles: [],
                companies: [],
            }
        },
        created() {
            this.getUsers();
            this.getRoles();
            this.getCompanies();
        },
        methods: {
            getUsers() {
                this.users = [];

                axios
                    .get('/api/users', {
                        params: {
                            with_companies: true,
                        }
                    })
                    .then(response => {
                        if (!_.isEmpty(response) && response.status === HTTP_OK && !_.isNil(response.data.result)) {
                            this.users = response.data.result;

                            for (let user of this.users) {
                                this.$set(user, 'company_ids', _.map(user.companies, 'id'));
                            }
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
                ;
            },
            getRoles() {
                this.roles = [];

                axios
                    .get('/api/roles')
                    .then(response => {
                        if (!_.isEmpty(response) && response.status === HTTP_OK && !_.isNil(response.data.result)) {
                            this.roles = response.data.result;
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
                ;
            },
            getCompanies() {
                this.companies = [];

                axios
                    .get('/api/companies')
                    .then(response => {
                        if (!_.isEmpty(response) && response.status === HTTP_OK && !_.isNil(response.data.result)) {
                            this.companies = response.data.result;
                        }
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    })
                ;
            },
            saveUser(user) {
                if (!_.isNil(user)) {
                    if (!_.isEmpty(user.company_ids)) {
                        axios
                            .put('/api/users', {
                                user: user,
                            })
                            .then(response => {
                                if (!_.isNil(response)) {
                                    this.error = response.data.error;
                                }
                            })
                            .catch(error => {
                                this.globalVars.globalError = this.createErrorFromRequest(error);
                            })
                        ;
                    } else {
                        this.error = new _Error(Status.WARNING, 0, this.__('validation.company_required'));
                    }
                }
            },
            deleteUser(userID) {
                if (!_.isNil(userID)) {
                    axios
                        .delete('/api/users/' + userID)
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;

                                this.getUsers();
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
        },
    }
</script>
