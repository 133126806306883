import Vue from 'vue';

export default class OcrApi {
    globalVars = Vue.observable({
        isLoading: false,
    });

    #baseUrl = '';
    #accessToken = '';

    #apiClient = null;

    constructor(baseUrl, accessToken) {
        this.#baseUrl = baseUrl;
        this.#accessToken = accessToken;

        this.init();
    }

    init() {
        if (_.isNil(this.#baseUrl) || this.#baseUrl === '') {
            return;
        }

        this.#apiClient = axios.create({
            baseURL: `${this.#baseUrl}/api`,
            headers: {
                Authorization: `Bearer ${this.#accessToken}`,
            },
        });

        this.#apiClient.interceptors.request.use(config => {
            this.globalVars.isLoading = true;

            return config;
        });
        this.#apiClient.interceptors.response.use(response => {
            this.globalVars.isLoading = false;

            return response;
        }, error => {
            this.globalVars.isLoading = false;

            return Promise.reject(error);
        });
    }

    get client() {
        return this.#apiClient;
    }

    get accessToken() {
        return this.#accessToken;
    }

    // Standard requests for the RmaApi
    getOcrProcesses(filters = {}) {
        let availableParams = [
            'non_downloaded',
        ];

        return this.client.get('/ocr-processes', {
            params: this.#createRequestParams(availableParams, filters),
        });
    }

    getOcrProcess(id) {
        return this.client.get(`/ocr-processes/${id}`);
    }

    createOcrProcess(files) {
        return this.client.post('/ocr-processes', files);
    }

    saveOcrProcess(id, data) {
        return this.client.post(`/ocr-processes/${id}`, data);
    }

    enqueueOcrProcess(id, mock = false) {
        return this.client
            .post(`/ocr-processes/${id}/queue`, {
                mock: mock,
            });
    }

    #createRequestParams(availableParams, providedParams) {
        let params = {};

        for (let param of availableParams) {
            if (providedParams.hasOwnProperty(param)) {
                params[param] = providedParams[param];
            }
        }

        return params;
    }
}
