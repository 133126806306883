var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"create-group-modal","tabindex":"-1","role":"dialog","aria-labelledby":"create-group-modal","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('model.group.name'))))]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('form',{attrs:{"id":"create-new-group-form","action":"","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.createNewGroup($event)}}},[_c('div',{staticClass:"modal-body"},[_c('error-message',{attrs:{"error":_vm.error}}),_vm._v(" "),(_vm.parentModels != null && _vm.parentModels.length > 0)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"select-model"}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('model.model.name'))))]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedModelID),expression:"selectedModelID"}],staticClass:"form-control",attrs:{"id":"select-model","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedModelID=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"0","disabled":""}},[_vm._v(_vm._s(_vm.__('text.select_item')))]),_vm._v(" "),_vm._l((_vm.parentModels),function(ref){
var id = ref.id;
var number = ref.number;
var name = ref.name;
return _c('option',{domProps:{"value":id}},[_vm._v("\n                                "+_vm._s(number)+": "+_vm._s(name)+"\n                            ")])})],2)]):_vm._e(),_vm._v(" "),(_vm.newGroup != null)?[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"model-number"}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('model.group.name')))+" "+_vm._s(_vm.__('model.group.attr.number')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newGroup.number),expression:"newGroup.number"}],staticClass:"form-control",attrs:{"type":"number","id":"model-number","required":"","title":_vm._f("capitalize")(_vm.__('title.input.model_field', {
                                       field: _vm.__('model.group.attr.number'),
                                       model: _vm.__('model.group.name')
                                   })),"placeholder":_vm._f("capitalize")(_vm.__('model.group.attr.number'))},domProps:{"value":(_vm.newGroup.number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newGroup, "number", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"model-name"}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('model.group.name')))+" "+_vm._s(_vm.__('model.group.attr.name')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newGroup.name),expression:"newGroup.name"}],staticClass:"form-control",attrs:{"type":"text","id":"model-name","required":"","title":_vm._f("capitalize")(_vm.__('title.input.model_field', {
                                       field: _vm.__('model.group.attr.name'),
                                       model: _vm.__('model.group.name')
                                   })),"placeholder":_vm._f("capitalize")(_vm.__('model.group.attr.name'))},domProps:{"value":(_vm.newGroup.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newGroup, "name", $event.target.value)}}})])]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(_vm._s(_vm.__('button.close')))]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.globalVars.isLoading}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('button.save', { model: _vm.__('model.group.name') }))))])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }