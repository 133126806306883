<template>
    <div>
        <div v-for="shop of shops" class="custom-control custom-checkbox">
            <input type="checkbox" :id="`shop-${shop.id}`" class="custom-control-input"
                   name="shops" :value="shop.id" v-model="selected"
                   :disabled="globalVars.isLoading"
                   @change="$emit('input', selected)"
            >
            <label class="custom-control-label" :for="`shop-${shop.id}`">{{ shop.name }}</label>
        </div>
    </div>
</template>

<script>
    export default {
        emits: [
            'input',
        ],
        props: {
            value: null,
        },
        data() {
            return {
                shops: [],
                selected: [],
            };
        },
        watch: {
            value: {
                immediate: true,
                handler() {
                    this.selected = this.value;
                },
            }
        },
        created() {
            this.fetchShops();
        },
        methods: {
            fetchShops() {
                axios
                    .get('/api/shops')
                    .then(response => {
                        this.shops = response.data.result;
                    })
                    .catch(error => {
                        this.globalVars.globalError = this.createErrorFromRequest(error);
                    });
            },
        },
    };
</script>
