<template>
    <div class="row">
        <div class="col">
            <error-message :error="error" :toast="true" positioning="floating" :timeout="timeout"
                           enter-animation="slideInLeft" leave-animation="slideOutLeft" classes="mt-3"></error-message>

            <h2>{{ __('account.manage.header') }}</h2>
            <p>{{ __('account.manage.page_info') }}</p>

            <div class="card">
                <div class="card-header">
                    <h3 class="hover-pointer m-0" data-toggle="collapse" data-target="#account-settings" aria-expanded="true" aria-controls="account-settings">
                        {{ __('account.manage.settings.account.header') }}
                    </h3>
                </div>

                <div id="account-settings" class="collapse show">
                    <div class="card-body">
                        Account information
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <h3 class="hover-pointer m-0" data-toggle="collapse" data-target="#app-settings" aria-expanded="false" aria-controls="app-settings">
                        {{ __('account.manage.settings.app.header') }}
                    </h3>
                </div>
                <div id="app-settings" class="collapse show">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="app-lang">{{ __('account.manage.settings.app.label.app_language') }}</label>
                            <select id="app-lang" class="form-control w-auto" v-model="globalVars.lang" @change="setLang(globalVars.lang)">
                                <option v-for="LANGUAGE of LANGUAGES" :value="LANGUAGE">{{ LANGUAGE.toUpperCase() }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorMessage from "../../components/ErrorMessage";

    export default {
        components: {
            ErrorMessage,
        },
        data() {
            return {
                LANGUAGES: LANGUAGES,
                error: new _Error(),
                timeout: true,
            }
        },
        methods: {
            setLang(lang) {
                if (lang) {
                    axios
                        .put('/api/users', {
                            user: {
                                lang: lang,
                            }
                        })
                        .then(response => {
                            if (!_.isNil(response)) {
                                this.changeLanguage(lang);

                                this.timeout = true;
                                this.error = response.data.error;
                            }
                        })
                        .catch(error => {
                            this.timeout = false;
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
        },
    }
</script>
