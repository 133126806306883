<template>
    <div class="container-fluid flex-grow-1 mb-3">
        <error-message :error="globalVars.globalError" :toast="true" positioning="fixed" enter-animation="fadeInLeft" leave-animation="fadeOutLeft" classes="mt-3"></error-message>

        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
    import OcrApi from '../OcrApi.js';
    import ErrorMessage from "../components/ErrorMessage";

    export default {
        components: {
            ErrorMessage,
        },
        props: {
            lang: {
                type: String,
                default: DEFAULT_LANG,
            },
            user: {
                type: Object,
                default: null,
            },
            ocrAccessToken: String,
        },
        created() {
            if (!_.isNil(this.lang) && this.lang !== '') {
                this.globalVars.lang = this.lang;
                this.changeLanguage(this.lang);
            }

            if (!_.isNil(this.user) && _.isObject(this.user)) {
                this.globalVars.user = this.user;
            }

            window.OcrApi = new OcrApi(process.env.MIX_EXPLODE2VIEW_OCR_API_ENDPOINT, this.ocrAccessToken);
        },
        mounted() {
            this.globalVars.csrf_token = $('meta[name="csrf-token"]').attr('content');
        },
    }
</script>
