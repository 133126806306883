<template>
    <div class="row">
        <div v-if="error != null" class="col-auto error-message" :class="[positioningStyle, classes]">
            <template v-if="toast">
                <transition :enter-active-class="'animated ' + enterAnimation" :leave-active-class="'animated ' + leaveAnimation">
                    <div v-show="error.message" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                        <div class="toast-header">
                            <div class="toast-indicator rounded mr-2" :class="[ error.severity ? 'bg-' + StatusMapper[error.severity] : '']"></div>
                            <strong class="mr-auto">{{ __('error_message.header') }}</strong>
                            <button v-if="closeable" type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" @click="close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="toast-body">
                            {{ error.message }}
                        </div>
                    </div>
                </transition>
            </template>
            <template v-else>
                <transition :enter-active-class="'animated ' + enterAnimation" :leave-active-class="'animated ' + leaveAnimation">
                    <div v-show="error.message" :class="['alert', error.severity ? 'alert-' + StatusMapper[error.severity] : '']">
                        <button v-if="closeable" type="button" class="close ml-1" aria-label="Close" @click="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {{ error.message }}
                    </div>
                </transition>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                StatusMapper: StatusMapper,
                timeoutId: null,
            }
        },
        props: {
            error: {
                type: Object,
                default: () => new _Error(),
            },
            classes: {
                type: String,
                default: '',
            },
            timeout: {
                type: Boolean,
                default: false,
            },
            timeoutDuration: {
                type: Number,
                default: 5000,
            },
            enterAnimation: {
                type: String,
                default: 'fadeIn',
            },
            leaveAnimation: {
                type: String,
                default: 'fadeOut',
            },
            closeable: {
                type: Boolean,
                default: true,
            },
            positioning: {
                type: String,
                default: '',
            },
            toast: {
                type: Boolean,
                default: false,
            }
        },
        watch: {
            'error.message': function () {
                if (!_.isNil(this.timeoutId)) {
                    clearTimeout(this.timeoutId);
                }

                if (!_.isNil(this.error) && this.error.message !== '' && this.timeout && this.timeoutDuration > 0) {
                    this.timeoutId = setTimeout(() => {
                        this.error.message = '';
                    }, this.timeoutDuration);
                }
            }
        },
        computed: {
            positioningStyle: function () {
                let cssClass = '';
                switch (this.positioning) {
                    case 'floating':
                        cssClass = 'alert-floating';
                        break;
                    case 'fixed':
                        cssClass = 'alert-fixed';
                        break;
                }

                return cssClass;
            },
        },
        methods: {
            close() {
                this.error.message = '';
            }
        },
    }
</script>
