var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"group-info-modal","tabindex":"-1","role":"dialog","aria-labelledby":"product-info-modal","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.theModelGroup.group.number)+": "+_vm._s(_vm.theModelGroup.group.name))]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('form',{attrs:{"id":"group-form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveGroup('group-form')}}},[_c('div',{staticClass:"modal-body"},[_c('fieldset',[_c('legend',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('model.group.name')))+" "+_vm._s(_vm.__('model.group.attr.image')))]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"group-image"}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('model.group.attr.image'))))]),_vm._v(" "),_c('input',{staticClass:"form-control",attrs:{"type":"file","id":"group-image","name":"image","accept":"image/jpeg,image/png","disabled":_vm.globalVars.isLoading,"required":""}})]),_vm._v(" "),(_vm.theModelGroup.image != null)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.__('text.current_image'))))]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:({
                                             fullscreen: false,
                                             next: false,
                                             rotatable: false,
                                             scalable: false,
                                             zoomRatio: 0.25,
                                             tooltip: false,
                                        }),expression:"{\n                                             fullscreen: false,\n                                             next: false,\n                                             rotatable: false,\n                                             scalable: false,\n                                             zoomRatio: 0.25,\n                                             tooltip: false,\n                                        }"}]},[_c('img',{staticClass:"w-100 hover-pointer",attrs:{"src":_vm.theModelGroup.image}})])]),_vm._v(" "),_c('div',{staticClass:"col-4"},[_c('confirm-buttons',{on:{"confirmed":_vm.deleteImage}})],1)])]):_vm._e()]),_vm._v(" "),_c('error-message',{attrs:{"error":_vm.error,"timeout":true,"classes":"mt-3"}})],1),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.globalVars.isLoading}},[_vm._v("\n                        "+_vm._s(_vm._f("capitalize")(_vm.__('button.save', {model: _vm.__('model.group.name')})))+" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.globalVars.isLoading),expression:"globalVars.isLoading"}],staticClass:"fas fa-circle-notch fa-spin"})])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }