<template>
    <div class="row">
        <div class="col">
            <h1>{{ __('account.header', { user: this.globalVars.user.name }) }}</h1>
            <p>{{ __('account.page_info') }}</p>

            <ul class="nav nav-pills">
                <li class="nav-item bg-white mr-1" v-for="route of allowedRoutes">
                    <router-link :to="route" class="nav-link border border-primary" active-class="active">
                        <i class="fas" :class="['fa-' + route.icon,]"></i> {{ __('nav.' + route.name) }}
                    </router-link>
                </li>
            </ul>

            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                routes: [
                    { name: 'manage_users', icon: 'user' },
                    { name: 'manage_companies', icon: 'industry' },
                    { name: 'manage_account', icon: 'cog' },
                ],
            }
        },
        computed: {
            allowedRoutes: function () {
                return this.routes.filter((route) => {
                    return this.hasPermission(route.name);
                });
            },
        },
    }
</script>

<style scoped>
    .nav-pills > .nav-item > .nav-link {
        padding: 0.2rem 1rem;
    }
</style>
