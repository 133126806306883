<template>
    <div class="modal fade" id="side-by-side-comparison-modal" tabindex="-1" role="dialog" aria-labelledby="side-by-side-comparison-modal"
         aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ __('imports.text.side_by_side.header') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <error-message :error="error"></error-message>

                    <template v-if="sideBySide">
                        <div class="row">
                            <div class="col"><h4>{{ __('imports.text.side_by_side.left.header') }}</h4></div>
                            <div class="col"><h4>{{ __('imports.text.side_by_side.right.header') }}</h4></div>
                        </div>
                        <div class="row">
                            <div class="col"><p>{{ __('imports.text.side_by_side.left.info') }}</p></div>
                            <div class="col"><p>{{ __('imports.text.side_by_side.right.info') }}</p></div>
                        </div>

                        <hr>

                        <table class="table table-borderless table-sm" v-for="comparison of sideBySide">
                            <thead>
                            <tr>
                                <th><b>{{ comparison.left.number }}</b></th>
                                <th><b>{{ comparison.right.number }}</b></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody v-if="comparison.left && comparison.right">
                            <tr v-for="LANG of LANGUAGES">
                                <template v-if="comparison.left.hasOwnProperty(LANG) && comparison.right.hasOwnProperty(LANG)">
                                    <!-- Left side -->
                                    <td class="align-middle">
                                        <div class="form-group row align-items-center mb-0">
                                            <label class="col-1 mb-0">{{ LANG.toUpperCase() }}</label>
                                            <div class="col">
                                                <input type="text" class="form-control" v-model="comparison.left[LANG]" disabled>
                                            </div>
                                        </div>
                                    </td>

                                    <!-- Right side -->
                                    <td>
                                        <div class="form-group row align-items-center mb-0">
                                            <label :for="comparison.right.id + '-' + LANG" class="col-1 mb-0">{{ LANG.toUpperCase() }}</label>
                                            <div class="col">
                                                <input type="text" :id="comparison.right.id + '-' + LANG" class="form-control"
                                                       v-model="comparison.right[LANG]" :disabled="globalVars.isLoading || successfullySaved">
                                            </div>
                                        </div>
                                    </td>

                                    <!-- Buttons -->
                                    <td class="align-middle">
                                        <button type="button" class="btn btn-success btn-sm" @click="saveTranslations(comparison.right)"><i class="fas fa-check"></i></button>
                                        <button type="button" class="btn btn-danger btn-sm" @click="removeComparisonByID(comparison.id, LANG)"><i class="fas fa-times"></i></button>
                                    </td>
                                </template>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        {{ __('imports.text.side_by_side.no_differences') }}
                    </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __('button.close') }}</button>
                    <button type="button" class="btn btn-primary" v-if="sideBySide"
                            :disabled="globalVars.isLoading || successfullySaved" @click="saveTranslations(rightSide)">
                        {{ __('button.save') | capitalize }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../../eventbus";
    import ErrorMessage from "../ErrorMessage";

    export default {
        components: {
            ErrorMessage,
        },
        data() {
            return {
                LANGUAGES: LANGUAGES,
                error: new _Error(),
                sideBySide: this.data,
                successfullySaved: false,
            }
        },
        props: {
            data: Array,
        },
        computed: {
            rightSide: function () {
                return _.map(this.sideBySide, (comparison) => {
                    return comparison.right ?? false;
                });
            },
        },
        watch: {
            data: function () {
                this.sideBySide = this.data;
            },
        },
        created() {
            EventBus.$on('toggle-side-by-side-modal', (state) => {
                if (!_.isNil(state) && (state === 'show' || state === 'hide')) {
                    $('#side-by-side-comparison-modal').modal(state);
                } else {
                    $('#side-by-side-comparison-modal').modal('toggle');
                }
            });
        },
        mounted() {
            $('#side-by-side-comparison-modal').on('hidden.bs.modal', () => {
                this.sideBySide = [];
                this.successfullySaved = false;
            })
        },
        destroyed() {
            EventBus.$off('toggle-side-by-side-modal');
        },
        methods: {
            saveTranslations(translations) {
                if (!_.isEmpty(translations)) {
                    if (!_.isArray(translations)) {
                        translations = [translations];
                    }

                    axios
                        .put('/api/translations', {
                            translations: translations,
                        })
                        .then(response => {
                            if (!_.isEmpty(response)) {
                                this.error = response.data.error;
                                this.successfullySaved = true;
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            removeComparisonByID(comparisonID, language) {
                if (comparisonID && language) {
                    let comparison = _.find(this.sideBySide, (comp) => comp.id === comparisonID);

                    if (!_.isNil(comparison)) {
                        this.$delete(comparison.left, language);
                        this.$delete(comparison.right, language);
                    }
                }
            },
        },
    }
</script>
